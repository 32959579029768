<template>
  <q-list>
    <q-item-label v-if="label" header>{{ label }}</q-item-label>
    <EmployerCard
      v-for="item in employers"
      :employer="item"
      :key="item.id"
      :profile="profile"
      :online="Boolean(online[item.id])"
    />
  </q-list>
</template>

<script setup>
import EmployerCard from "@/components/employers/card.vue";
import { computed, defineProps } from "vue";

const props = defineProps({
  label: String,
  employers: Array,
  here: {
    type: Array,
    default: () => [],
  },
  profile: {
    type: Boolean,
    default: false,
  },
});

const online = computed(() =>
  props.here.reduce((acc, item) => {
    acc[item.id] = true;
    return acc;
  }, {}),
);
</script>
