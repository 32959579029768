<template>
  <q-btn flat @click.stop="store.employer.super ? openTransfer() : null">
    <div class="flex items-center q-gutter-x-xs">
      <span v-if="!store.employer?.super" class="q-mr-md">
        {{ store.employer.points }}
      </span>
      <img src="@/assets/point.svg" style="width: 20px; height: 20px" />
    </div>
    <q-menu class="q-pa-sm" v-if="!store.employer.super">
      <q-item dense>
        <q-item-section> Мои пойнты </q-item-section>
        <q-item-section side>{{ store.employer.points }}</q-item-section>
      </q-item>
      <q-item dense>
        <q-item-section> Бюджет </q-item-section>
        <q-item-section side>{{ store.employer.budget }}</q-item-section>
      </q-item>
      <q-separator class="q-my-sm"></q-separator>
      <q-btn
        class="full-width"
        color="positive"
        label="Перевести"
        noCaps
        @click="openTransfer"
        v-close-popup
      ></q-btn>
    </q-menu>
  </q-btn>
  <q-dialog v-model="transferDialog">
    <q-card v-if="transferDialog" style="width: 400px">
      <q-toolbar class="bg-teal text-white">
        <q-toolbar-title class="flex items-center">
          <img
            src="@/assets/point.svg"
            style="width: 24px; height: 24px"
            class="rotate-coin q-mr-sm"
          />
          <span>Перевод пойнтов</span>
        </q-toolbar-title>
        <q-btn dense flat icon="close" v-close-popup></q-btn>
      </q-toolbar>
      <q-card-section
        v-if="store.employer.super"
        class="text-caption text-primary"
      >
        Совершая перевод «Пойнтов» доверенному сотруднику, он сможет создавать
        задачи с вознаграждением, а так же переводить «Пойнты» за достижение
        целей или плана продаж.
      </q-card-section>
      <q-item v-else class="text-body1">
        <q-item-section>
          <q-item-label>Мои пойнты</q-item-label>
          <q-item-label>Бюджет</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-item-label>{{ store.employer.points }}</q-item-label>
          <q-item-label>{{ store.employer.budget }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-card-section class="q-gutter-y-sm">
        <div class="text-caption">Перевести пойнты</div>
        <q-option-group
          v-if="store.employer.super"
          v-model="transfer.to"
          :options="[
            { label: 'На личный счет', value: 'self' },
            { label: 'На бюджет', value: 'budget' },
          ]"
          color="primary"
          inline
        />
        <q-option-group
          v-else-if="store.employer.budget"
          v-model="transfer.from"
          :options="[
            { label: 'Из личного счета', value: 'self' },
            { label: 'Из бюджета', value: 'budget' },
          ]"
          color="primary"
          inline
        />
        <EmployersSelector
          v-model="employer"
          @update:model-value="transfer.to_id = $event.id"
        />
        <q-input
          dense
          standout
          v-model="transfer.text"
          label="Комментарий"
          type="textarea"
          rows="2"
        ></q-input>
        <q-input
          dense
          standout
          v-model.number="transfer.points"
          label="Количество"
          mask="#"
          reverse-fill-mask
        >
          <template v-slot:after>
            <q-btn
              no-caps
              color="positive"
              icon="check"
              @click="sendPoints"
              :disable="!transfer.to_id || !transfer.points"
            ></q-btn>
          </template>
        </q-input>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useStore } from "@/store";
import { ref } from "vue";
import EmployersSelector from "../selectors/employers-selector.vue";
import { useQuasar } from "quasar";
import { axios } from "@/services";
import { confirm } from "@/helpers";

const store = useStore();
const transferDialog = ref(false);
const $q = useQuasar();
const employer = ref();
const transfer = ref({});

const sendPoints = async () => {
  if (
    !(await confirm(
      `Вы собираетесь отправить ${transfer.value.points} пойнтов пользователю ${employer.value.full_name}`,
    ))
  )
    return false;
  try {
    await axios.post("employer/transfer", transfer.value);
    $q.notify("Перевод успешно выполнен");
    if (!store.employer.super) {
      if (transfer.value.from == "budget") {
        store.employer.budget -= transfer.value.points;
        return;
      }
      if (transfer.value.from == "self") {
        store.employer.points -= transfer.value.points;
      }
    }
  } catch (e) {
    //
  }
};

const openTransfer = () => {
  transfer.value = {
    from: "self",
    to: "self",
  };
  transferDialog.value = true;
};
</script>
