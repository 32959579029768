<template>
  <q-input
    v-for="contact in items"
    :key="contact.id"
    dense
    readonly
    :label="contact.name"
    v-model="contact.value"
    :mask="contact.type == 'phone' ? phoneMask : ''"
    :prefix="contact.type == 'phone' ? '+7' : ''"
    :suffix="
      contact.type == 'phone' && contact.add ? `Доб: ${contact.add}` : ''
    "
  >
    <template v-slot:prepend>
      <q-icon name="mail" v-if="contact.type == 'email'"></q-icon>
      <q-icon name="call" v-else></q-icon>
    </template>
    <template v-slot:append>
      <q-btn dense flat round class="q-mr-sm" icon="arrow_drop_down">
        <q-menu>
          <q-list dense>
            <template v-if="showIntegrations && contact.type == 'phone'">
              <q-item
                clickable
                v-close-popup
                @click.stop="
                  $bus.emit('integrations/open', {
                    id: integration.id,
                    phone: contact.value,
                  })
                "
                v-for="integration in store.integrations['phone']"
                :key="integration.id"
              >
                <q-item-section side>
                  <q-img
                    :src="getStorage(integration.logo)"
                    width="18px"
                  ></q-img>
                </q-item-section>
                <q-item-section>{{ integration.name }}</q-item-section>
              </q-item>
            </template>
            <q-item clickable @click="edit(contact)" v-close-popup>
              <q-item-section>Изменить контакт</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </template>
  </q-input>
  <div class="text-right q-mt-mb">
    <q-btn
      flat
      no-caps
      color="positive"
      @click="edit({})"
      label="Добавить контакт"
      icon="add"
      size="sm"
    >
    </q-btn>
  </div>
  <q-dialog v-model="showDialog">
    <q-card style="width: 400px">
      <q-toolbar class="bg-positive text-white">
        <q-toolbar-title>
          <span v-if="model.id">{{ model.name }}</span>
          <span v-else>Новый контакт</span>
        </q-toolbar-title>
        <div>
          <q-btn
            dense
            flat
            icon="delete"
            @click="destroy"
            color="negative"
            v-if="model.id"
          ></q-btn>
          <q-btn dense flat icon="close" v-close-popup></q-btn>
        </div>
      </q-toolbar>
      <q-card-section class="q-gutter-y-sm">
        <q-input
          dense
          standout
          autofocus
          v-model="model.name"
          label="Имя контакта"
        ></q-input>
        <q-input
          dense
          v-model="model.value"
          :mask="model.type == 'phone' ? phoneMask : ''"
          :prefix="model.type == 'phone' ? '+7' : ''"
          :error="errors"
          error-message="Данные введены не корректно"
          hide-bottom-space
          unmasked-value
          standout
          class="q-mb-sm"
        >
          <template v-slot:before>
            <q-select
              dense
              options-dense
              standout
              label="Тип"
              style="width: 100px"
              :options="ContactTypes"
              v-model="model.type"
              emit-value
              map-options
            ></q-select>
          </template>
        </q-input>
        <q-input
          v-if="model.type == 'phone'"
          dense
          standout
          v-model="model.add"
          label="Добавочный номер"
          maxlength="6"
        ></q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn no-caps color="primary" label="Отмена" v-close-popup />
        <q-btn no-caps color="positive" label="Сохранить" @click="save" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { axios } from "@/services";
import { ContactTypes } from "@/static";
import { useStore } from "@/store";
import { inject, ref, defineProps } from "vue";
import { useQuasar } from "quasar";
import { confirm, getStorage } from "@/helpers";
import { phoneMask } from "@/constants";

const props = defineProps({
  id: String,
  items: Array,
  showIntegrations: {
    type: Boolean,
    default: true,
  },
  showInfo: {
    type: Boolean,
    default: true,
  },
});
const model = ref({});
const errors = ref(false);
const showDialog = ref(false);
const store = useStore();
const $bus = inject("bus");
const $q = useQuasar();

const save = async () => {
  errors.value = false;
  const { value } = model;
  try {
    if (value.id) {
      await axios.put(`contacts/${value.id}`, value);
      $q.notify("Контакт успешно изменен");
    } else {
      const { data } = await axios.post("contacts", value);
      props.items.push(data);
      $q.notify("Новый контакт успешно добавлен");
    }
    showDialog.value = false;
    model.value = {};
  } catch (e) {
    if (e?.response?.status == 422) {
      errors.value = true;
    }
  }
};
const destroy = async () => {
  if (!(await confirm())) {
    return;
  }

  try {
    await axios.delete(`contacts/${model.value.id}`);
    const index = props.items.findIndex((item) => item.id == model.value.id);
    if (index > -1) {
      props.items.splice(index, 1);
    }
    showDialog.value = false;
    $q.notify("Контакт успешно удален");
  } catch (e) {
    //
  }
};

const edit = (item) => {
  if (!item.id) {
    item.type = "phone";
    item.client_id = props.id;
  }
  model.value = item;
  showDialog.value = true;
};
</script>
