<template>
  <q-avatar size="md" v-if="employer?.photo">
    <img :src="getStorage(employer.photo)" />
  </q-avatar>
  <q-avatar size="md" v-else color="positive" class="text-white">
    {{ props.employer?.name?.[0] || props.employer?.email[0] }}
  </q-avatar>
</template>

<script setup>
import { getStorage } from "@/helpers";
import { defineProps } from "vue";

const props = defineProps({
  employer: Object,
});
</script>
