<template>
  <q-field dense standout label-slot :model-value="displayValue">
    <template v-slot:control>
      {{ displayValue }}
    </template>
    <template v-slot:label>
      <span v-if="label">{{ label }}</span>
      <span v-else>
        <span v-if="time">Дата / время</span>
        <span v-else>Дата</span>
      </span>
    </template>
    <template v-slot:append>
      <q-btn dense flat icon="calendar_month" :disable="disable">
        <q-popup-proxy
          cover
          transition-show="scale"
          transition-hide="scale"
          v-model="dateProxy"
          v-if="!disable"
        >
          <q-date
            :model-value="modelValue"
            mask="YYYY-MM-DD"
            @update:modelValue="changeDate"
          ></q-date>
        </q-popup-proxy>
      </q-btn>
      <q-btn dense flat icon="schedule" :disable="disable" v-if="time">
        <q-menu
          auto-close
          cover
          max-height="250px"
          @show="updatePosition"
          v-if="!disable"
        >
          <q-scroll-area style="height: 250px; width: 80px" ref="area">
            <q-list dense class="full-width">
              <q-item
                clickable
                @click="changeTime(item)"
                v-for="item in times"
                :key="item"
                :active="item == timeValue"
              >
                <q-item-section>{{ item }}</q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </q-menu>
      </q-btn>
    </template>
  </q-field>
</template>

<script setup>
import { date } from "quasar";
import { computed, onMounted, ref, defineEmits, defineProps } from "vue";

const props = defineProps({
  modelValue: String,
  label: String,
  time: Boolean,
  disable: Boolean,
  dateFormat: {
    type: String,
    default: "DD.MM.YYYY",
  },
  timeFormat: {
    type: String,
    default: "HH:mm",
  },
});
const emit = defineEmits(["update:modelValue"]);
const times = ref([]);
const dateProxy = ref(false);
const area = ref();

onMounted(() => {
  for (let i = 0; i < 24; i++) {
    const h = ("0" + i).slice(-2);
    for (let j = 0; j < 60; j += 15) {
      const m = ("0" + j).slice(-2);
      times.value.push(h + ":" + m);
    }
  }
});

const displayValue = computed(() => {
  if (!dateValue.value && !timeValue.value) {
    return null;
  }
  return props.time ? dateValue.value + " " + timeValue.value : dateValue.value;
});

const dateValue = computed(() => {
  if (!props.modelValue) {
    return null;
  }
  return date.formatDate(props.modelValue, props.dateFormat);
});

const timeValue = computed(() => {
  if (!props.modelValue) {
    return null;
  }
  return date.formatDate(props.modelValue, props.timeFormat);
});

const updatePosition = () => {
  const time = timeValue.value || date.formatDate(new Date(), "HH:mm");
  const h = time.slice(0, 2);
  area.value.setScrollPercentage("vertical", h / 22, 200);
};

const changeDate = (value) => {
  const time = timeValue.value || date.formatDate(new Date(), "HH:mm");
  const result = value + " " + time + ":00";
  emit("update:modelValue", result);
  dateProxy.value = false;
};

const changeTime = (value) => {
  const newTime = value + ":00";
  const newDate = date.formatDate(props.modelValue || new Date(), "YYYY-MM-DD");
  const result = newDate + " " + newTime;
  emit("update:modelValue", result);
};
</script>
