import script from "./field.vue?vue&type=script&setup=true&lang=js"
export * from "./field.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSelect from 'quasar/src/components/select/QSelect.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QPopupProxy,QDate,QBtn,QTime,QList,QItem,QItemSection,QCheckbox,QSelect});qInstall(script, 'directives', {ClosePopup});
