<template>
  <div v-if="employers" class="flex items-center">
    <q-badge
      @click.stop.prevent="dialog = true"
      class="cursor-pointer"
      color="positive"
    >
      <q-icon name="person"></q-icon>
      {{ employers.length }}
    </q-badge>
    <q-dialog v-model="dialog">
      <q-card style="width: 350px">
        <q-toolbar class="bg-positive text-white">
          <q-toolbar-title>Участники совещания</q-toolbar-title>
          <q-btn dense flat round icon="close" v-close-popup></q-btn>
        </q-toolbar>
        <q-card-section>
          <EmployersList :employers="employers"></EmployersList>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import EmployersList from "@/components/employersList.vue";
export default {
  components: {
    EmployersList,
  },
  props: {
    employers: Array,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
