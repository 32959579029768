<template>
  <div class="q-gutter-y-sm">
    <q-input
      dense
      standout
      :label="field.name"
      :model-value="modelValue"
      v-if="field.type == 'date' || field.type == 'datetime'"
      :mask="field.type == 'date' ? '##.##.####' : '##.##.#### ##:##'"
      @update:model-value="updateValue"
      @blur="save"
      :error="error"
      error-message="Обязательно для заполнения"
      hide-bottom-space
    >
      <template v-slot:append>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-date
              title="Выберите дату"
              color="positive"
              :model-value="modelValue"
              :mask="field.type == 'date' ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm'"
              fill-mask
              @update:model-value="updateValue"
            >
              <div class="row items-center justify-end">
                <q-btn v-close-popup no-caps label="Выбрать" color="positive" />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
        <q-icon
          name="access_time"
          class="cursor-pointer"
          v-if="field.type == 'datetime'"
        >
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-time
              title="Выберите время"
              color="positive"
              :model-value="modelValue"
              mask="DD.MM.YYYY HH:mm"
              format24h
              @update:model-value="updateValue"
            >
              <div class="row items-center justify-end">
                <q-btn v-close-popup no-caps label="Выбрать" color="positive" />
              </div>
            </q-time>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
    <q-input
      v-else-if="
        field.type == 'string' || field.type == 'link' || field.type == 'phone'
      "
      dense
      standout
      :label="field.name"
      :model-value="modelValue"
      :mask="field.type == 'phone' ? '###-###-##-##' : ''"
      :prefix="field.type == 'phone' ? '+7' : ''"
      unmasked-value
      @update:model-value="updateValue"
      @blur="save"
      :error="error"
      error-message="Обязательно для заполнения"
      hide-bottom-space
    >
      <template v-slot:append v-if="field.type == 'link' && modelValue">
        <div>
          <q-btn
            size="sm"
            dense
            flat
            round
            icon="content_copy"
            @click.stop.prevent="copy"
          ></q-btn>
          <q-btn
            size="sm"
            dense
            flat
            round
            icon="open_in_new"
            @click.stop.prevent="open"
          ></q-btn>
        </div>
      </template>
    </q-input>
    <q-input
      v-else-if="field.type == 'number' || field.type == 'money'"
      dense
      standout
      :label="field.name"
      :model-value="modelValue"
      mask="#"
      reverse-fill-mask
      @update:model-value="updateValue"
      @blur="save"
      :error="error"
      error-message="Обязательно для заполнения"
      hide-bottom-space
    >
      <template v-slot:append v-if="field.type == 'money'">
        <q-icon name="currency_ruble"></q-icon>
      </template>
    </q-input>
    <q-list separator dense class="dashed" v-else-if="field.type == 'file'">
      <q-item>
        <q-item-section>{{ field.name }}</q-item-section>
        <q-item-section side>
          <Uploader :src="`fields/set/${field.id}/${modelId}`"></Uploader>
        </q-item-section>
      </q-item>
      <q-item v-if="error">
        <q-item-section class="text-negative text-caption">
          Обязательно для заполнения
        </q-item-section>
      </q-item>
      <FileIcon
        :file="file"
        v-for="(file, i) in modelValue"
        :key="file.id"
        @destroy="props.modelValue.splice(i, 1)"
        canDelete
      ></FileIcon>
    </q-list>
    <q-checkbox
      :label="field.name"
      color="positive"
      :model-value="modelValue || false"
      @update:model-value="saveAndUpdateValue"
      v-else-if="field.type == 'boolean'"
      left-label
      class="full-width flex justify-between"
    />
    <q-input
      type="textarea"
      autogrow
      standout
      dense
      :label="field.name"
      :model-value="modelValue"
      @update:model-value="updateValue"
      @blur="save"
      :error="error"
      error-message="Обязательно для заполнения"
      hide-bottom-space
      v-else-if="field.type == 'text'"
    />
    <q-select
      v-else-if="field.type == 'select' || field.type == 'multiselect'"
      dense
      standout
      options-dense
      use-chips
      :error="error"
      error-message="Обязательно для заполнения"
      hide-bottom-space
      :label="field.name"
      :multiple="field.type == 'multiselect'"
      :options="field.list"
      :model-value="modelValue"
      @update:model-value="saveAndUpdateValue"
    />
  </div>
</template>

<script setup>
import axios from "axios";
import FileIcon from "../fileIcon.vue";
import { copyToClipboard, useQuasar } from "quasar";
import Uploader from "@/components/uploader.vue";
import {
  nextTick,
  onMounted,
  ref,
  toRefs,
  defineProps,
  defineEmits,
} from "vue";

const props = defineProps({
  modelValue: String,
  field: Object,
  modelId: String,
  error: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
const oldValue = ref(null);
const $q = useQuasar();
const { field, error, modelId, modelValue } = toRefs(props);

onMounted(() => {
  oldValue.value = props.modelValue;
});

const copy = () => {
  copyToClipboard(props.modelValue);
  $q.notify("Данные успешно скопированы");
};

const updateValue = (data) => {
  emit("update:modelValue", data);
};

const save = async () => {
  if (
    oldValue.value == modelValue.value &&
    field.value.type != "select" &&
    field.value.type != "multiselect"
  ) {
    return false;
  }
  const data = {};
  if (modelValue.value) {
    data["data"] = modelValue.value;
  }
  try {
    await axios.post(`fields/set/${field.value.id}/${modelId.value}`, data);
    oldValue.value = props.modelValue;
  } catch (e) {
    emit("update:modelValue", null);
  }
};

const saveAndUpdateValue = async (data) => {
  emit("update:modelValue", data);
  nextTick(save);
};

const open = () => {
  window.open(props.modelValue, "_blank");
};
</script>
