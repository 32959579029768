<template>
  <div class="q-gutter-y-sm">
    <q-select
      dense
      standout
      use-input
      options-dense
      :autofocus="autofocus"
      :clearable="clearable"
      input-debounce="800"
      label="Клиент"
      option-label="name"
      option-value="id"
      :options="clients"
      @filter="onSearch"
      :loading="loading"
      :model-value="modelValue"
      @update:model-value="updateModelValue"
      @new-value="save"
      ref="selector"
    >
      <template v-slot:no-option="{ inputValue }" v-if="createable">
        <q-item
          :clickable="Boolean(inputValue)"
          @click="save(inputValue)"
          style="max-width: 320px"
        >
          <q-item-section>
            <q-item-label> Клиентов не найдено. </q-item-label>
            <q-item-label caption>
              Для добавления нового клиента введите его имя нажмите ввод или
              коснитесь здесь
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps" style="max-width: 320px">
          <q-item-section>{{ scope.opt.name }}</q-item-section>
        </q-item>
      </template>
      <template v-slot:append v-if="info">
        <q-btn
          dense
          flat
          icon="account_circle"
          @click.stop.prevent="$bus.emit('clients/showDialog', modelValue.id)"
          v-if="modelValue?.id"
        ></q-btn>
      </template>
    </q-select>
    <span
      class="text-caption text-amber"
      v-if="modelValue && !modelValue.id && info"
    >
      Клиент не найден, при добавлении сделки, будет создан новый
    </span>
    <ClientContacts
      :items="modelValue?.contacts || []"
      :showIntegrations="integrations"
      :showInfo="showInfo"
      :id="modelValue?.id"
      v-if="modelValue && contacts"
    ></ClientContacts>
  </div>
</template>

<script setup>
import { axios } from "@/services";
import ClientContacts from "@/components/contactsList.vue";
import { ref, toRefs, defineEmits, defineProps, inject } from "vue";
import { useQuasar } from "quasar";

const props = defineProps({
  modelValue: Object,
  autofocus: {
    type: Boolean,
    default: false,
  },
  integrations: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Boolean,
    default: false,
  },
  contacts: {
    type: Boolean,
    default: false,
  },
  createable: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
});
const {
  modelValue,
  autofocus,
  integrations,
  info,
  contacts,
  createable,
  clearable,
} = toRefs(props);
const emit = defineEmits(["update:modelValue"]);
const clients = ref([]);
const loading = ref(false);
const search = ref("");
const $q = useQuasar();
const $bus = inject("bus");
const selector = ref();

const save = async (input) => {
  try {
    const { data } = await axios.post("clients", { name: input });
    $q.notify("Новый клиент успешно добавлен");
    clients.value.push(data);
    selector.value.toggleOption(data, false);
  } catch (e) {
    //
  }
};

const updateModelValue = (client) => {
  emit("update:modelValue", client);
};

const onSearch = (q, update) => {
  update(async () => {
    loading.value = true;
    search.value = q;
    try {
      const { data } = await axios.get("clients", { params: { q } });
      clients.value = data.data;
    } catch (e) {
      //
    } finally {
      loading.value = false;
    }
  });
};
</script>
