<template>
  <q-item @click="open" class="full-width rounded-borders" clickable>
    <q-item-section side v-if="withIcon">
      <q-img :src="icon" width="32px"> </q-img>
    </q-item-section>
    <q-item-section>
      <q-item-label>
        <div class="ellipsis" style="max-width: 240px">
          {{ file.name }}
        </div>
      </q-item-label>
      <q-item-label v-if="withSize">
        <small>{{ format.humanStorageSize(file.size) }}</small>
      </q-item-label>
    </q-item-section>
    <q-item-section side>
      <div class="q-gutter-x-xs">
        <q-btn
          size="sm"
          round
          flat
          icon="delete"
          @click.stop.prevent="destroy"
        ></q-btn>
        <q-btn
          dense
          round
          flat
          icon="download"
          size="sm"
          @click.stop.prevent="download"
          :disable="loading"
        ></q-btn>
      </div>
    </q-item-section>
  </q-item>
</template>

<script setup>
import { FileIcons } from "@/static";
import { format, useQuasar } from "quasar";
import { axios } from "@/services";
import { onMounted, ref, defineEmits, defineProps, toRefs } from "vue";
import { confirm } from "@/helpers";

const props = defineProps({
  file: Object,
  withIcon: {
    type: Boolean,
    default: false,
  },
  withSize: {
    type: Boolean,
    default: false,
  },
  canDelete: {
    type: Boolean,
    default: false,
  },
});

const { file, withIcon, withSize } = toRefs(props);
const icon = ref(FileIcons.unknown);
const loading = ref(false);
const $q = useQuasar();
const emit = defineEmits(["destroy"]);

onMounted(() => {
  let type = "unknown";
  const ext = file.value.ext?.toLowerCase();
  if (["jpg", "jpeg", "gif", "bmp", "png", "svg"].indexOf(ext) > -1) {
    type = "image";
  } else if (["doc", "docx", "pages", "ods"].indexOf(ext) > -1) {
    type = "doc";
  } else if (["pdf"].indexOf(ext) > -1) {
    type = "pdf";
  } else if (["txt"].indexOf(ext) > -1) {
    type = "text";
  } else if (["js", "php", "h", "json", "html"].indexOf(ext) > -1) {
    type = "code";
  } else if (["zip", "rar", "tar", "gz"].indexOf(ext) > -1) {
    type = "zip";
  } else if (["xls", "xlsx", "numbers", "xml", "csv"].indexOf(ext) > -1) {
    type = "xls";
  } else if (["ppt"].indexOf(ext) > -1) {
    type = "ppt";
  } else if (
    ["mp3", "wav", "wma", "ogg", "oga", "mogg", "m4p", "m4a"].indexOf(ext) > -1
  ) {
    type = "audio";
  } else if (
    ["avi", "wmv", "mp4", "mpg", "mpeg", "3gp", "webm", "mkv", "flv"].indexOf(
      ext,
    ) > -1
  ) {
    type = "video";
  }
  icon.value = FileIcons[type];
});

const open = async () => {
  try {
    const { data } = await axios.get(`files/${file.value.id}/link`);
    window.open(data, "_blank");
  } catch (e) {
    //
  }
};

const destroy = async () => {
  const isConfirm = await confirm("Подтвердите удаление файла");
  if (!isConfirm) {
    return;
  }
  try {
    await axios.delete(`files/${file.value.id}`);
    $q.notify(`Файл ${file.value.name} успешно удален`);
    emit("destroy");
  } catch (e) {
    //
  }
};

const download = async () => {
  loading.value = true;
  const notification = $q.notify({
    group: false,
    timeout: 0,
    spinner: true,
    message: "Ожидайте загрузки файла...",
  });
  try {
    const { data } = await axios.get(`files/${file.value.id}`, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    a.href = url;
    a.download = file.value.name;
    a.click();
    a.remove();
  } catch (e) {
    //
  } finally {
    loading.value = false;
    notification({
      icon: "done",
      spinner: false,
      message: "Загрузка завершена!",
      color: "positive",
      timeout: 500,
    });
  }
};
</script>
