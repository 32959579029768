<template>
  <div>
    <q-card>
      <q-card-section>
        <div class="text-h1">Ой</div>
        <div class="text-subtitle">Страница, которую вы ищите не найдена</div>
      </q-card-section>
      <q-card-section>
        <q-btn
          dense
          no-caps
          color="positive"
          @click="$router.back()"
          label="Вернуться назад"
        />
      </q-card-section>
    </q-card>
  </div>
</template>
