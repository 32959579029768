import "./styles/quasar.scss";
import lang from "quasar/lang/ru.js";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';;

export default {
  config: {},
  plugins: { Notify, Dialog, AppFullscreen },
  lang: lang,
};
