<template>
  <q-drawer
    v-model="isOpen"
    persistent
    side="right"
    :width="400"
    overlay
    behavior="mobile"
    @before-show="onOpen"
  >
    <q-toolbar class="text-white bg-teal">
      <q-toolbar-title>
        Уведомления
        <span class="text-caption">({{ total }})</span>
      </q-toolbar-title>
      <div class="q-gutter-x-md">
        <q-btn dense flat icon="cleaning_services"></q-btn>
        <q-btn dense flat icon="close" @click="isOpen = false"></q-btn>
      </div>
    </q-toolbar>
    <q-inner-loading :showing="loading" />
    <q-list separator>
      <q-item
        v-for="item in items"
        :key="item.id"
        @click="onClick(item)"
        :clickable="Boolean(item.data?.target)"
      >
        <q-item-section>
          <q-item-label caption>
            <div class="flex justify-between items-center">
              <span>
                {{ date.formatDate(item.created_at, "DD.MM.YYYY HH:mm") }}
              </span>
              <q-btn
                dense
                flat
                icon="close"
                color="negative"
                @click.stop="destroy(item)"
              ></q-btn>
            </div>
          </q-item-label>
          <q-item-label caption>{{ item.data.text }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item
        clickable
        @click="loadItems"
        v-if="total > items.length"
        class="q-mt-md"
      >
        <q-item-section class="text-center text-primary"
          >Загрузить еще</q-item-section
        >
      </q-item>
    </q-list>
  </q-drawer>
</template>

<script setup>
import { axios } from "@/services";
import { useStore } from "@/store";
import { date } from "quasar";
import { ref, onMounted, onBeforeUnmount, inject } from "vue";

const isOpen = ref(false);
const items = ref([]);
const $bus = inject("bus");
const store = useStore();
const loading = ref(true);
const total = ref(0);
let page = 0;

const onOpen = () => {
  page = 0;
  items.value = [];
  loadItems();
};

const loadItems = async () => {
  page++;
  loading.value = true;
  const params = {
    page,
  };
  try {
    const { data } = await axios.get("notifications", { params });
    items.value = [...items.value, ...data.data];
    store.unread = 0;
    total.value = data.total;
  } catch (e) {
    //
  } finally {
    loading.value = false;
  }
};

const destroy = async (item) => {
  try {
    await axios.post("notifications/destroy", {
      ids: [item.id],
    });
    items.value = items.value.filter(({ id }) => id != item.id);
    total.value--;
  } catch (e) {
    //
  }
};

const onClick = (item) => {
  const { target, id } = item.data;
  if (target == "task") {
    $bus.emit("tasks/showDialog", id);
    return;
  }
  if (target == "order") {
    $bus.emit("orders/showDialog", id);
    return;
  }
  if (target == "meeting") {
    $bus.emit("meetings/showDialog", id);
    return;
  }
};

const onCallDialog = () => {
  isOpen.value = true;
};

onMounted(() => {
  $bus.on("notifications/showDialog", onCallDialog);
});

onBeforeUnmount(() => {
  $bus.off("notifications/showDialog", onCallDialog);
});
</script>
