<template>
  <q-header elevated>
    <q-toolbar>
      <q-btn
        dense
        flat
        no-caps
        @click="store.toggleSettings('sidebar')"
        icon="menu"
        class="q-mr-sm q-ml-sm"
      >
        <q-tooltip>Убрать меню</q-tooltip>
      </q-btn>
      <OrdersSelector
        label="Поиск сделок"
        hide-dropdown-icon
        @update:modelValue="$bus.emit('orders/showDialog', $event.id)"
        v-if="store.can('leads') || store.can('orders')"
      ></OrdersSelector>
      <q-space />
      <q-item :to="store.employer?.super ? '/settings' : null">
        <q-item-section v-if="store.user.demo && store.employer.super">
          <q-item-label class="text-positive">
            Демо {{ store.user.demo }} дн
          </q-item-label>
        </q-item-section>
        <q-item-section
          class="text-amber"
          v-if="store.employer?.company?.readonly"
        >
          Доступ ограничен
        </q-item-section>
      </q-item>
      <Points></Points>
      <Clock></Clock>
      <q-separator vertical inset class="q-mx-sm" />
      <q-btn
        dense
        flat
        no-caps
        color="amber"
        icon="notifications"
        v-if="store.employer"
        @click="$bus.emit('notifications/showDialog')"
      >
        <q-badge floating color="negative" rounded v-if="store.unread">
          {{ store.unread }}
        </q-badge>
      </q-btn>
      <q-btn
        dense
        flat
        no-caps
        color="positive"
        icon="people"
        v-if="store.employer"
      >
        <q-badge floating color="primary" rounded>
          {{ store.online.length }}
        </q-badge>
        <q-menu flat style="min-width: 300px">
          <EmployersList
            :employers="store.online"
            :profile="true"
            label="Сотрудники в сети"
          ></EmployersList>
        </q-menu>
      </q-btn>
      <q-btn
        dense
        flat
        no-caps
        color="positive"
        @click="$q.fullscreen.toggle()"
        :icon="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'"
      />
      <q-separator vertical inset class="q-mx-sm" />
      <q-btn dense flat no-caps>
        <Avatar :employer="store.employer || store.user" />
        <q-menu>
          <q-list bordered padding dense>
            <q-item
              clickable
              :to="{ name: 'profile', params: { id: store.employer.id } }"
            >
              <q-item-section avatar>
                <q-icon name="account_circle"></q-icon>
              </q-item-section>
              <q-item-section>Профиль</q-item-section>
            </q-item>
            <q-item clickable :to="{ name: 'faq' }">
              <q-item-section avatar>
                <q-icon name="quiz"></q-icon>
              </q-item-section>
              <q-item-section>FAQ</q-item-section>
            </q-item>
            <q-item clickable @click="logout">
              <q-item-section avatar>
                <q-icon name="power_settings_new"></q-icon>
              </q-item-section>
              <q-item-section>Выход</q-item-section>
            </q-item>
            <q-separator spaced />
            <q-item dense v-if="store.employer">
              <q-item-section>
                <q-item-label>Уведомления Telegram</q-item-label>
              </q-item-section>
              <q-item-section side top>
                <q-toggle
                  color="positive"
                  :model-value="store.user.tg"
                  @update:model-value="toggleTelegram"
                />
              </q-item-section>
            </q-item>
            <q-item dense>
              <q-item-section>
                <q-item-label>Звуки</q-item-label>
              </q-item-section>
              <q-item-section side top>
                <q-toggle
                  color="positive"
                  :model-value="Boolean(store.user?.ui.sounds)"
                  @update:model-value="store.toggleSettings('sounds')"
                />
              </q-item-section>
            </q-item>

            <q-item dense>
              <q-item-section>
                <q-item-label>Темная тема</q-item-label>
              </q-item-section>
              <q-item-section side top>
                <q-toggle
                  color="positive"
                  :model-value="Boolean(store.user?.ui.dark)"
                  @update:model-value="toggleTheme"
                />
              </q-item-section>
            </q-item>

            <q-item dense>
              <q-item-section>
                <q-item-label>Уведомлять о зачислении пойнтов</q-item-label>
              </q-item-section>
              <q-item-section side top>
                <q-toggle
                  color="positive"
                  :model-value="Boolean(store.user?.ui.points)"
                  @update:model-value="store.toggleSettings('points')"
                />
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </q-toolbar>
  </q-header>
  <q-dialog v-model="tgDialog">
    <q-card style="width: 500px">
      <q-toolbar class="bg-positive text-white">
        <q-toolbar-title>Подключение Telegram бота</q-toolbar-title>
        <q-btn dense flat round icon="close" v-close-popup></q-btn>
      </q-toolbar>
      <q-card-section>
        <p class="text-weight-bolder">
          Вы можете получать уведомления и управлять своими задачами прямо из
          Telegram. Подключить его можно тремя способами.
        </p>
        <q-list>
          <q-item>
            <q-item-section>
              <q-item-label class="text-weight-bolder">
                Способ 1.
              </q-item-label>
              <q-item-label caption>
                Наведите камеру вашего смартфона на QR – код. У вас откроется
                приложение Telegram.
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section class="items-center">
              <q-img src="@/assets/tg.gif" width="200px"></q-img>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label class="text-weight-bolder">
                Способ 2.
              </q-item-label>
              <q-item-label caption>
                Если на вашем компьютере установлен Telegram, нажмите на кнопку
                ниже. Бот откроется в приложении.
              </q-item-label>
              <q-item-label>
                <a
                  href="#"
                  class="text-blue text-weight-bolder"
                  @click.prevent="goToBot"
                >
                  Открыть Telegram
                </a>
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label class="text-weight-bolder">
                Способ 3.
              </q-item-label>
              <q-item-label caption>
                Откройте приложение Telegram на смартфоне, и в поиске найдите
                <span class="text-weight-bolder text-blue"> @RostPoint </span>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script setup>
import EmployersList from "@/components/employersList.vue";
import Avatar from "@/components/employers/avatar.vue";
import OrdersSelector from "@/components/selectors/orders-selector.vue";
import Clock from "@/components/layout/clock.vue";
import Points from "@/components/layout/points.vue";
import { axios } from "@/services";
import { useStore } from "@/store";
import { inject, ref } from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";

const store = useStore();
const $bus = inject("bus");
const tgDialog = ref(false);
const $q = useQuasar();
const router = useRouter();

const goToBot = () => {
  window.open(process.env.VUE_APP_TG_URL, "_blank");
};

const toggleTheme = () => {
  store.toggleSettings("dark");
  $q.dark.toggle();
};

const toggleTelegram = async () => {
  try {
    const { data } = await axios.post("toggleTg");
    store.user.tg = data.state;
    if (!data.success) {
      tgDialog.value = true;
    }
  } catch (e) {
    //
  }
};

const logout = async () => {
  await store.logout();
  router.push({ name: "login" });
};
</script>
