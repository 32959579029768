export const tailFormatter = (q, values) => {
  if (values?.length !== 3) {
    return "";
  }
  const rem = q % 10;
  if (rem === 1) {
    return values[0];
  } else if (rem > 1 && rem < 5) {
    return values[1];
  }
  return values[2];
};

export const numberFormat = (value, isCurrency = false) => {
  const num = String(value).replace(/[^\d.-]+/g, "");
  const number = new Intl.NumberFormat(
    "ru-RU",
    isCurrency
      ? {
          style: "currency",
          currency: "RUB",
        }
      : {},
  );
  return number.format(num);
};

export const phoneFormatter = (value) => {
  if (!value) {
    return "";
  }
  const part1 = value.slice(0, 3);
  const part2 = value.slice(3, 6);
  const part3 = value.slice(6, 8);
  const part4 = value.slice(-2);
  return `+7 ${part1}-${part2}-${part3}-${part4}`;
};

export const secToTimeFormatter = (value) => {
  if (!value) return "00:00:00";
  const d = Math.floor(value / 86400);
  value -= d * 86400;
  let h = Math.floor(value / 3600);
  const hs = h * 3600;
  if (h < 10) h = "0" + h;
  let m = Math.floor((value - hs) / 60);
  const ms = m * 60;
  if (m < 10) m = "0" + m;
  let s = value - hs - ms;
  if (s < 10) s = "0" + s;
  const prefix = d ? `${d}д ` : "";
  return `${prefix}${h}:${m}:${s}`;
};

export const minToTimeFormatter = (value) => {
  if (!value) return "00:00";
  const d = Math.floor(value / 1440);
  value -= d * 1440;
  let h = Math.floor(value / 60);
  const hs = h * 60;
  if (h < 10) h = "0" + h;
  let m = value - hs;
  if (m < 10) m = "0" + m;
  const prefix = d ? `1д ` : "";
  return `${prefix}${h}:${m}`;
};

export const minToDaysFormatter = (value) => {
  return Math.floor(value / 1440);
};
