<template>
  <q-select
    dense
    standout
    options-dense
    :options="store.funnels"
    v-model="selected"
    :label="label || 'Выбор этапа'"
    :disable="disable"
  >
    <template v-slot:selected>
      {{ board?.name }}
    </template>
    <template v-slot:option="scope">
      <q-item-label header>{{ scope.opt.name }}</q-item-label>
      <q-list dense>
        <q-item
          clickable
          v-ripple
          dense
          v-close-popup
          @click="emit('update:modelValue', board.id)"
          :class="{ 'text-primary': props.modelValue === board.id }"
          v-for="board in scope.opt.boards"
          :key="board.id"
        >
          <q-item-section side class="q-pr-none">
            <q-icon
              name="circle"
              size="xs"
              :style="{ color: board.color }"
            ></q-icon>
          </q-item-section>
          <q-item-section>
            <q-item-label v-html="board.name" class="q-ml-md"></q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </template>
  </q-select>
</template>

<script setup>
import { useStore } from "@/store";
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  modelValue: Object,
  label: String,
  disable: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["emit"]);
const store = useStore();

const selected = computed(() =>
  store.funnels.find(
    (item) => item.boards?.findIndex(({ id }) => id == props.modelValue) > -1,
  ),
);

const board = computed(() =>
  selected.value?.boards.find(({ id }) => id == props.modelValue),
);
</script>
