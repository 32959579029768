<template>
  <q-dialog
    v-model="isOpen"
    persistent
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-layout
      view="hHh lpR lfr"
      container
      style="width: 800px; max-width: 80vw"
    >
      <q-header>
        <q-toolbar v-if="task?.id">
          <div class="flex q-gutter-x-sm items-center">
            <q-toolbar-title>Задача</q-toolbar-title>
            <q-badge :color="TaskPriorityMap[task.priority]">
              #{{ task.number }}
            </q-badge>
            <Status :status="task.status"></Status>
            <span class="text-subtitle2">
              {{ date.formatDate(task.created_at, "DD.MM.YYYY HH:mm") }}
            </span>
          </div>
          <q-space></q-space>
          <div class="flex items-center">
            <span v-if="task.points" class="q-mr-sm">{{ task.points }}</span>
            <img
              v-if="task.points"
              src="@/assets/point.svg"
              style="width: 20px"
            />
            <div class="q-gutter-x-sm">
              <q-btn
                dense
                flat
                round
                icon="delete"
                color="negative"
                @click="destroy"
                v-if="store.can('tasks', 2)"
              >
                <q-tooltip>Удалить</q-tooltip>
              </q-btn>
              <q-btn dense flat round icon="close" @click="close"></q-btn>
            </div>
          </div>
        </q-toolbar>
        <q-toolbar v-else>
          <q-toolbar-title>Новая задача</q-toolbar-title>
          <q-btn dense flat round icon="close" @click="close"></q-btn>
        </q-toolbar>
      </q-header>
      <q-page-container class="bg-page">
        <q-page class="q-pt-sm q-px-sm">
          <div class="row q-col-gutter-x-sm">
            <div class="col-md-5 col-12 items-stretch">
              <q-card flat class="full-width">
                <q-scroll-area style="height: calc(100vh - 111px)">
                  <q-card-section>
                    <q-field
                      label="От кого"
                      :model-value="true"
                      dense
                      standout
                      class="q-mb-sm"
                    >
                      <template v-slot:control>
                        {{ task.creator?.full_name || "Создана автоматически" }}
                      </template>
                    </q-field>
                    <EmployersSelector
                      v-model="task.employer"
                      label="Исполнитель"
                      class="q-mb-sm"
                      @update:modelValue="update"
                    ></EmployersSelector>
                    <ItemSelector
                      v-model="task.task_type"
                      label="Тип задачи"
                      class="q-mb-sm"
                      model="taskTypes"
                      @update:modelValue="update"
                      :editable="store.can('tasks', 3)"
                    ></ItemSelector>
                    <PointsSelector
                      v-model="task.points"
                      class="q-mb-sm"
                      v-if="
                        (store.employer.budget || store.employer.super) &&
                        !task.id
                      "
                      @update:model-value="update"
                    ></PointsSelector>
                    <q-checkbox
                      v-model="task.allDay"
                      color="positive"
                      label="Весь день"
                      @update:modelValue="update"
                    ></q-checkbox>
                    <DateTimeSelector
                      v-model="task.start"
                      :time="!task.allDay"
                      label="Приступить"
                      class="q-mb-sm"
                      @update:modelValue="changeDates"
                    ></DateTimeSelector>
                    <DateTimeSelector
                      v-model="task.end"
                      :time="!task.allDay"
                      label="Завершить"
                      class="q-mb-sm"
                      @update:modelValue="changeDates"
                    ></DateTimeSelector>
                    <q-input
                      dense
                      standout
                      v-model="task.text"
                      type="textarea"
                      rows="2"
                      autogrow
                      label="Описание"
                      @blur="update"
                    ></q-input>
                  </q-card-section>
                  <q-card-section v-if="task.id">
                    <q-btn
                      disable
                      outline
                      size="xl"
                      :label="secToTimeFormatter(time)"
                      class="full-width q-mb-sm"
                      :color="task.overdue ? 'negative' : 'positive'"
                    ></q-btn>
                    <div
                      v-if="
                        task.employer_id == store.employer.id ||
                        (!task.employer_id &&
                          task.employers.filter(({ id }) => id == employer.id)
                            .length)
                      "
                    >
                      <div v-if="task.status == 'new'">
                        <q-btn
                          dense
                          noCaps
                          label="Приступить"
                          color="positive"
                          class="full-width"
                          @click="start"
                        ></q-btn>
                      </div>
                      <div
                        v-else-if="task.status == 'work'"
                        class="q-gutter-y-sm"
                      >
                        <q-btn
                          dense
                          no-caps
                          label="Пауза"
                          color="amber"
                          class="full-width"
                          @click="pause"
                        ></q-btn>
                        <q-btn
                          dense
                          no-caps
                          label="Завершить"
                          color="positive"
                          class="full-width"
                          @click="done"
                          v-if="!task.triggers?.length"
                        ></q-btn>
                        <div v-else class="q-gutter-y-sm">
                          <q-btn
                            dense
                            no-caps
                            :label="trigger.name"
                            color="positive"
                            class="full-width"
                            @click="done(i)"
                            v-for="(trigger, i) in task.triggers"
                            :key="i"
                          ></q-btn>
                        </div>
                      </div>
                      <div
                        v-else-if="task.status == 'pause'"
                        class="q-gutter-y-sm"
                      >
                        <q-btn
                          dense
                          no-caps
                          label="Продолжить"
                          color="positive"
                          class="full-width"
                          @click="start"
                        ></q-btn>
                      </div>
                    </div>
                    <div class="q-gutter-y-sm" v-if="task.status == 'done'">
                      <q-badge
                        color="positive"
                        class="text-body1 full-width justify-between"
                      >
                        <span>Завершено</span>
                        <span>{{
                          date.formatDate(task.done_at, "DD.MM.YYYY")
                        }}</span>
                      </q-badge>
                      <q-badge
                        color="positive"
                        class="text-body1 full-width justify-between"
                        v-if="task.trigger"
                      >
                        <span>Триггер</span>
                        <span>{{ task.trigger }}</span>
                      </q-badge>
                      <q-badge
                        color="negative"
                        class="text-body1 full-width justify-between"
                        v-if="task.overdue"
                      >
                        <span>Превышено на</span>
                        <span> {{ getOverdue }} </span>
                      </q-badge>
                    </div>
                  </q-card-section>
                  <q-card-section v-else>
                    <q-btn
                      dense
                      no-caps
                      class="full-width"
                      color="positive"
                      label="Создать"
                      @click="create"
                    ></q-btn>
                  </q-card-section>
                </q-scroll-area>
              </q-card>
            </div>
            <div class="col-md-7 col-12">
              <MessageList
                model="tasks"
                :id="task.id"
                v-if="task.id"
                height="calc(100vh - 171px)"
              ></MessageList>
            </div>
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
  </q-dialog>
</template>

<script setup>
import MessageList from "@/components/layout/messageList.vue";
import Status from "@/components/status.vue";
import ItemSelector from "@/components/selectors/item-selector.vue";
import EmployersSelector from "@/components/selectors/employers-selector.vue";
import DateTimeSelector from "@/components/selectors/datetime-selector.vue";
import PointsSelector from "@/components/selectors/pointsSelector.vue";
import { TaskPriorityMap } from "@/static";
import { date, useQuasar } from "quasar";
import { axios } from "@/services";
import { ref, computed, onBeforeUnmount, inject, onMounted } from "vue";
import { confirm } from "@/helpers";
import { secToTimeFormatter } from "@/formatters";
import { useStore } from "@/store";

const isOpen = ref(false);
const task = ref({});
const time = ref(0);
const $bus = inject("bus");
const $q = useQuasar();
const store = useStore();
let queue = 0;
let interval = null;

const getOverdue = computed(() => {
  const { value } = task;
  if (!value) {
    return;
  }
  let diff = 0;
  if (value.done_at && value.end) {
    const done = new Date(value.done_at);
    const end = new Date(value.end);
    diff = date.getDateDiff(done, end, "seconds");
  }
  return secToTimeFormatter(diff);
});

const loadTask = async (id) => {
  try {
    const { data } = await axios.get(`tasks/${id}`);
    task.value = data;
    isOpen.value = true;
    updateTimer();
    interval = setInterval(updateTimer, 1000);
  } catch (e) {
    //
  }
};

const updateTimer = () => {
  const { value } = task;
  if (!value) {
    return;
  }
  let timeValue = value.work_time;
  if (value.status == "work") {
    timeValue += date.getDateDiff(
      new Date(),
      new Date(value.start_at),
      "seconds",
    );
  }
  time.value = timeValue;
  checkOverdue();
};

const checkOverdue = () => {
  const { value } = task;
  if (!value || value.overdue || !value.end) {
    return;
  }
  task.value.overdue = date.getDateDiff(new Date(), value.end, "seconds") > 0;
};

const pause = async () => {
  if (!(await confirm("Подтвердите приостановку выполнения задачи"))) {
    return;
  }
  try {
    await axios.post(`tasks/${task.value.id}/pause`);
  } catch (e) {
    //
  }
};

const start = async () => {
  if (!(await confirm())) {
    return false;
  }
  try {
    await axios.post(`tasks/${task.value.id}/start`);
  } catch (e) {
    //
  }
};

const done = async (i) => {
  if (!(await confirm())) {
    return false;
  }

  try {
    await axios.post(`tasks/${task.value.id}/done`, {
      trigger: i,
    });
  } catch (e) {
    //
  }
};

const create = async () => {
  const model = {
    allDay: task.value.allDay,
    creator_id: task.value.creator?.id,
    employer_id: task.value.employer?.id,
    start: new Date(task.value.start),
    end: new Date(task.value.end),
    task_type_id: task.value.task_type?.id,
    text: task.value.text,
    points: task.value.points,
    meeting_id: task.value.meeting?.id,
    order_id: task.value.order?.id,
  };
  try {
    const { data } = await axios.post("tasks", model);
    task.value = data;
    if (model.points) {
      store.employer.budget -= data.points;
    }
  } catch (e) {
    //
  }
};

const update = async () => {
  const { value } = task;
  if (!value.id || value.status == "done") {
    return;
  }
  try {
    await axios.put(`tasks/${task.value.id}`, {
      allDay: value.allDay,
      text: value.text,
      start: value.start,
      end: value.end,
      task_type_id: value.task_type?.id,
      employer_id: value.employer?.id,
    });
  } catch (e) {
    //
  }
};
const changeDates = async () => {
  const { value } = task;
  if (!value.id || value.status == "done") {
    return;
  }
  try {
    await axios.post(`tasks/${task.value.id}/changeDates`, {
      start: value.start,
      end: value.end,
    });
  } catch (e) {
    //
  }
};
const close = () => {
  if (interval) {
    clearInterval(interval);
  }
  isOpen.value = false;
};

const onCallDialog = (id, relation) => {
  queue = ++store.dialogsQueue;
  if (id) {
    loadTask(id);
  } else {
    task.value = {
      allDay: true,
      order_id: relation?.order?.id,
      meeting_id: relation?.meeting?.id,
      order: relation?.order,
      meeting: relation?.meeting,
      employer: store.employer,
      creator: store.employer,
    };
    isOpen.value = true;
  }
};

const onTasksDestroy = (id) => {
  if (id == task.value?.id) {
    $q.notify({
      color: "amber",
      message: "Задача удалена",
    });
    close();
  }
};

const onTasksUpdate = (data) => {
  if (task.value?.id == data.id) {
    task.value = data;
  }
};

const onCloseDialog = () => {
  if (store.dialogsQueue-- == queue) {
    isOpen.value = false;
  }
};

const destroy = async () => {
  const isConfirm = await confirm();
  if (!isConfirm) {
    return;
  }
  try {
    await axios.delete(`tasks/${task.value.id}`);
    close();
  } catch (e) {
    //
  }
};

onMounted(() => {
  $bus.on("tasks/showDialog", onCallDialog);
  $bus.on("tasks/destroy", onTasksDestroy);
  $bus.on("tasks/update", onTasksUpdate);
  $bus.on("closeDialog", onCloseDialog);
});

onBeforeUnmount(() => {
  $bus.off("tasks/showDialog", onCallDialog);
  $bus.off("tasks/destroy", onTasksDestroy);
  $bus.off("tasks/update", onTasksUpdate);
  $bus.of("closeDialog", onCloseDialog);
});
</script>
