<template>
  <q-dialog v-model="isOpen" persistent @hide="onHide">
    <q-card style="width: 450px">
      <q-card-section v-if="isOpen">
        <p class="text-subtitle" v-if="item.from_name">
          Сотрудник {{ item.from_name }} перевел вам {{ item.points }}
          {{ tailFormatter(item.points, ["пойнт", "пойнта", "пойнтов"]) }}.
        </p>
        <p>
          {{ item.text }}
        </p>
        <div class="flex items-center justify-between">
          <q-checkbox
            dense
            label="Больше не показывать"
            v-model="hideAfterClose"
          ></q-checkbox>
          <q-btn
            color="positive"
            label="Закрыть"
            noCaps
            @click="isOpen = false"
          ></q-btn>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { inject, onBeforeUnmount, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { play } from "@/helpers";
import { tailFormatter } from "@/formatters";

const isOpen = ref(false);
const $bus = inject("bus");
const store = useStore();
const item = ref();
const hideAfterClose = ref(false);

const onCallDialog = (data) => {
  if (data.to_id != store.employer.id) {
    return;
  }
  item.value = data;
  if (!store.user?.ui.points) {
    pointsUp(data.points);
    return;
  }
  isOpen.value = true;
};

const onHide = () => {
  if (hideAfterClose.value) {
    store.toggleSettings("points");
  }
  if (store.user?.ui.sounds) {
    play("cash");
  }
  pointsUp(item.value.points);
};

const pointsUp = async (amount) => {
  const newValue = store.employer.points + amount;
  const step = Math.floor(amount / (Math.log(amount) + 10));
  while (amount > 0) {
    await new Promise((resolve) => {
      setTimeout(() => {
        if (store.employer.points + step > newValue) {
          store.employer.points = newValue;
        } else {
          store.employer.points += step;
        }
        resolve();
      }, 70);
    });
    amount -= step;
  }
};

onMounted(() => {
  $bus.on("points/showDialog", onCallDialog);
});

onBeforeUnmount(() => {
  $bus.off("points/showDialog", onCallDialog);
});
</script>
