<template>
  <q-dialog
    v-model="isOpen"
    persistent
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="full-height" style="width: 400px">
      <q-toolbar class="text-white bg-teal">
        <q-toolbar-title>Настройка уровней сотрудника</q-toolbar-title>
        <q-btn dense flat icon="close" v-close-popup></q-btn>
      </q-toolbar>
      <q-card-section>
        <q-btn
          flat
          no-caps
          color="positive"
          icon="add"
          label="Добавить уровень"
          @click="edit({})"
        />
        <q-scroll-area class="h-l3">
          <draggable
            class="q-list q-list--separator"
            v-model="store.levels"
            v-bind="dragOptions"
            group="actions"
            item-key="id"
            @change="change"
          >
            <template #item="{ element }">
              <q-item>
                <q-item-section>{{ element.name }}</q-item-section>
                <q-item-section top side>
                  <div class="q-gutter-xs">
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      round
                      flat
                      dense
                      no-caps
                      color="primary"
                      icon="edit"
                      @click="edit(element)"
                    />
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      round
                      flat
                      dense
                      no-caps
                      color="negative"
                      icon="delete"
                      @click="destroy(element)"
                    />
                  </div>
                </q-item-section>
              </q-item>
            </template>
          </draggable>
        </q-scroll-area>
      </q-card-section>
      <q-dialog v-model="modelDialog" persistent>
        <q-card v-if="model" style="width: 300px">
          <q-toolbar class="bg-positive text-white">
            <q-toolbar-title>
              <span v-if="model.id">{{ model.name }}</span>
              <span v-else>Новый уровень</span>
            </q-toolbar-title>
            <q-btn dense flat icon="close" v-close-popup></q-btn>
          </q-toolbar>
          <q-card-section class="q-gutter-y-sm">
            <q-input
              dense
              standout
              v-model="model.name"
              label="Название"
            ></q-input>
            <q-input
              dense
              standout
              v-model="model.text"
              label="Описание"
              type="textarea"
              rows="4"
              counter
              maxlength="255"
            ></q-input>
            <q-input
              dense
              standout
              v-model="model.limit"
              label="Месячный лимит"
              mask="#"
              reverse-fill-mask
            ></q-input>
            <div class="flex justify-between items-center">
              <span>
                <q-btn
                  dense
                  no-caps
                  label="Иконка"
                  @click="iconDialog = true"
                />
              </span>
              <span v-if="model.icon != 'undefined'">
                <q-img :src="LevelIcons[model.icon]" width="32px" />
              </span>
            </div>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn no-caps color="primary" label="Отмена" v-close-popup />
            <q-btn no-caps color="positive" label="Сохранить" @click="save" />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <q-dialog v-model="iconDialog">
        <q-card style="width: 450px">
          <q-toolbar class="bg-positive text-white">
            <q-toolbar-title>Выберите иконку</q-toolbar-title>
            <q-space></q-space>
            <q-btn flat icon="close" v-close-popup></q-btn>
          </q-toolbar>
          <q-card-section>
            <div class="row q-col-gutter-sm">
              <div
                class="col-md-3 col-6"
                v-for="(icon, i) in LevelIcons"
                :key="i"
              >
                <q-btn class="full-width" @click="setIcon(i)">
                  <q-img :src="icon" />
                </q-btn>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </q-card>
  </q-dialog>
</template>

<script setup>
import draggable from "vuedraggable";
import { axios } from "@/services";
import { useStore } from "@/store";
import { useQuasar } from "quasar";
import { confirm } from "@/helpers";
import { ref, onMounted, onBeforeUnmount, inject } from "vue";
import { dragOptions } from "@/constants";
import { LevelIcons } from "@/static";

const model = ref({});
const modelDialog = ref(false);
const isOpen = ref(false);
const iconDialog = ref(false);
const store = useStore();
const $q = useQuasar();
const $bus = inject("bus");

const edit = (item) => {
  if (!item.limit) {
    item.limit = 0;
  }
  model.value = item;
  modelDialog.value = true;
};

const setIcon = (i) => {
  model.value.icon = i;
  iconDialog.value = false;
};

const destroy = async (level) => {
  if (!(await confirm(`Вы собираетесь удалить уровень ${level.name}`)))
    return false;
  try {
    await axios.delete(`levels/${level.id}`);
    store.levels = store.levels.filter(({ id }) => id != level.id);
    $q.notify(`Уровень ${level.name} успешно удален`);
  } catch (e) {
    //
  }
};

const save = async () => {
  const { value } = model;
  try {
    if (value.id) {
      await axios.put(`levels/${value.id}`, value);
      $q.notify("Данные успешно сохранены");
    } else {
      const { data } = await axios.post("levels", value);
      store.levels.push(data);
      $q.notify("Новый уровень успешно добавлен");
    }
    modelDialog.value = false;
    model.value = null;
  } catch (e) {
    //
  }
};

const change = async (evt) => {
  if (evt.moved) {
    try {
      const { newIndex, element } = evt.moved;
      await axios.post(`fields/${element.id}/move`, { sort: newIndex });
    } catch (e) {
      //
    }
  }
};

const onCallDialog = () => {
  isOpen.value = true;
};

onMounted(() => {
  $bus.on("levels/showDialog", onCallDialog);
});

onBeforeUnmount(() => {
  $bus.off("levels/showDialog", onCallDialog);
});
</script>

<style lang="sass" scoped>
.overlapping
  border: 2px solid white
  position: absolute
</style>
