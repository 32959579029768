<template>
  <q-layout view="lHr Lpr lfr">
    <Navbar />
    <BooksEditDialog />
    <FieldsEditDialog />
    <LevelsEditDialog />
    <OrdersShowDialog />
    <OrderCreateDialog />
    <ClientShowDialog />
    <TasksShowDialog />
    <MeetingShowDialog />
    <StageMessagesDialog />
    <PointsDialog />
    <NotificationsDialog />
    <EmailVerifyDialog @success="welcomeDialog" />
    <input
      type="file"
      ref="filesRef"
      multiple
      class="hidden"
      @change="sendFiles"
    />
    <q-inner-loading
      :showing="store.user?.lock"
      label="Идет процесс обновления системы, ожидайте..."
      label-style="font-size: 1.5em"
      class="lock-screen"
    />
    <q-drawer
      :model-value="Boolean(store.user?.ui.sidebar)"
      elevated
      :width="220"
      class="main"
      @mouseleave="companyExpand = false"
    >
      <q-scroll-area
        :horizontal-thumb-style="{ opacity: 0 }"
        class="full-height"
      >
        <q-list dense>
          <q-expansion-item
            class="q-mb-md"
            v-model="companyExpand"
            v-if="store.companies.length"
          >
            <template v-slot:header>
              <q-item-section avatar>
                <q-avatar>
                  <img
                    v-if="store.employer?.company.logo"
                    :style="{ width: '38px', height: '38px' }"
                    :src="getStorage(store.employer.company.logo)"
                  />
                  <img
                    v-else
                    :style="{ width: '38px', height: '38px' }"
                    src="@/assets/rp.svg"
                  />
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <q-item-label class="ellipsis" :style="{ maxWidth: '100px' }">
                  {{ store.employer?.company.name || "Мои компании" }}
                </q-item-label>
              </q-item-section>
            </template>
            <q-list>
              <q-item
                v-for="item in store.companies"
                :active="store.employer?.company_id == item.id"
                :key="item.id"
                :clickable="
                  item.active && item.id != store.employer?.company_id
                "
                :disable="!item.active"
                @click="changeCompany(item)"
              >
                <q-tooltip v-if="!item.active">
                  Доступ ограничен руководителем
                </q-tooltip>
                <q-item-section avatar>
                  <q-avatar>
                    <img
                      :style="{ width: '38px', height: '38px' }"
                      v-if="item.logo"
                      :src="getStorage(item.logo)"
                    />
                    <img
                      :style="{ width: '38px', height: '38px' }"
                      v-else
                      src="@/assets/rp.svg"
                    />
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ item.name }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable @click="createCompany">
                <q-item-section>Создать новую</q-item-section>
              </q-item>
            </q-list>
          </q-expansion-item>
          <div v-else>
            <q-item clickable v-ripple @click="createCompany">
              <q-item-section>Создать компанию</q-item-section>
            </q-item>
          </div>
          <div>
            <q-item clickable v-ripple :active="$route.active" exact to="/">
              <q-item-section avatar>
                <q-icon name="home" />
              </q-item-section>
              <q-item-section>Дашборд</q-item-section>
            </q-item>
          </div>
          <div v-for="item in store.modulesMap" :key="item.route">
            <q-item
              clickable
              v-ripple
              :active="$route.active"
              :to="{ name: item.id }"
            >
              <q-item-section avatar>
                <q-icon :name="item.icon" />
              </q-item-section>
              <q-item-section>{{ item.name }}</q-item-section>
            </q-item>
          </div>
          <div v-if="store.employer?.super">
            <q-item
              clickable
              v-ripple
              :active="$route.active"
              :to="{ name: 'settings' }"
            >
              <q-item-section avatar>
                <q-icon name="settings" />
              </q-item-section>
              <q-item-section>Настройки</q-item-section>
            </q-item>
            <q-item
              clickable
              v-ripple
              :active="$route.active"
              :to="{ name: 'integrations-list' }"
            >
              <q-item-section avatar>
                <q-icon name="extension" />
              </q-item-section>
              <q-item-section>Интеграции</q-item-section>
            </q-item>
          </div>
        </q-list>
      </q-scroll-area>
    </q-drawer>
    <q-page-container>
      <transition
        enter-active-class="animated fadeInLeft"
        leave-active-class="animated fadeOut"
      >
        <RouterView class="q-pa-sm" />
      </transition>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import Navbar from "@/components/layout/navbar.vue";
import {
  FieldsEditDialog,
  LevelsEditDialog,
  BooksEditDialog,
  OrdersShowDialog,
  ClientShowDialog,
  OrderCreateDialog,
  TasksShowDialog,
  MeetingShowDialog,
  StageMessagesDialog,
  EmailVerifyDialog,
  PointsDialog,
  NotificationsDialog,
} from "@/dialogs";
import { axios } from "@/services";
import { inject, onBeforeUnmount, onMounted, ref } from "vue";
import { getStorage, prompt } from "@/helpers";
import { useStore } from "@/store";
import { useQuasar } from "quasar";

const companyExpand = ref(false);
const store = useStore();
const $q = useQuasar();
const $bus = inject("bus");
const uploadSrc = ref("");
const filesRef = ref();

const checkInvites = async () => {
  if (store.user?.lock) {
    return;
  }
  const { data } = await axios.get("invites");
  data.map((item) => {
    $q.dialog({
      title: "Приглашение",
      html: true,
      persistent: true,
      message: `
        <p class="text-subtitle">Уважаемый ${store.user.name}!</p>
        <p>Вы получили приглашение на работу от компании ${item.name}</p>
        `,
      ok: {
        noCaps: true,
        color: "positive",
        label: "Принять",
      },
      cancel: {
        noCaps: true,
        color: "negative",
        label: "Отклонить",
      },
    })
      .onOk(async () => {
        try {
          await axios.post(`invites/${item.id}/resolve`);
          changeCompany(item);
        } catch (e) {
          //
        }
      })
      .onCancel(async () => {
        try {
          await axios.post(`invites/${item.id}/reject`);
          $q.notify("Вы отклонили приглашение");
        } catch (e) {
          //
        }
      });
  });
};

const changeCompany = async (item) => {
  if (item.id == store.employer?.company_id) {
    return;
  }
  try {
    await axios.post(`changeCompany/${item.id}`);
    location.reload();
  } catch (e) {
    //
  }
};

const createCompany = async () => {
  const name = await prompt("Введите название компании");
  if (!name) return false;
  try {
    const { data } = await axios.post("companies", { name });
    changeCompany(data);
  } catch (e) {
    //
  }
};

const welcomeDialog = () => {
  $q.dialog({
    cancel: {
      noCaps: true,
      label: "Ожидать приглашения",
    },
    ok: {
      noCaps: true,
      label: "Создать компанию",
      color: "positive",
    },
    html: true,
    title: "Добро пожаловать в RostPoint!",
    message: `<p class="text-subtitle">Уважаемый ${store.user.name}!<p>
            <p>В нашей системе организована работа внутри компаний. Для начала работы создайте ее прямо сейчас.</p>
            <p>Если вы являетесь сотрудником другой компании, ожидайте приглашения от руководителя.</p>
          `,
  })
    .onOk(createCompany)
    .onCancel(checkInvites);
};

const escHandler = (e) => {
  if (e.keyCode == 27 && store.dialogsQueue) {
    $bus.emit("closeDialog");
  }
};

onMounted(async () => {
  $q.dark.set(Boolean(store.user?.ui.dark));
  document.addEventListener("keydown", escHandler);
  window.Echo.join("system").listen("LockEvent", ({ value }) => {
    $q.notify({
      color: value ? "negative" : "positive",
      message: `Работа системы ${value ? "остановлена" : "восстановлена"}`,
    });
    store.user.lock = value;
  });
  window.Echo.join(`user.${store.user.id}`).listen(
    "UserEvent",
    ({ event, data }) => {
      console.log("userEvent", event, data);
      if (event === "activated") {
        const company = store.changeCompanyActive(data, 1);
        $q.notify({
          color: "positive",
          message: `Доступ к компании ${company.name} воостановлен.`,
        });
        return;
      }
      if (event === "deactivated") {
        store.changeCompanyActive(data, 0);
        if (store.employer?.company_id == data) {
          $q.dialog({
            title: "Внимание",
            message: "Доступ к компании был ограничен руководителем",
            cancel: false,
            color: "negative",
            ok: {
              label: "Закрыть",
              noCaps: true,
              color: "positive",
            },
          }).onOk(() => {
            location.reload();
          });
        }
        return;
      }
      if (event == "deleted") {
        const company = store.changeCompanyActive(data, 0);
        store.companies = store.companies.filter(({ id }) => id != data);
        $q.dialog({
          title: "Внимание",
          message: `Вы были удалены из компании ${company?.name}`,
          cancel: false,
          color: "negative",
          ok: {
            label: "Закрыть",
            noCaps: true,
            color: "positive",
          },
        }).onOk(() => {
          if (data == store.employer?.company_id) {
            location.reload();
          }
        });
      }
      if (event == "invite") {
        checkInvites();
      }
    },
  );

  $bus.on("tasks/add", (task) => {
    const { employer } = store;
    if (task.employer_id == employer.id && task.creator_id != employer.id) {
      $q.notify({
        timeout: 10000,
        progress: true,
        message: "Новая задача",
        caption:
          (task.task_type?.name || "") +
          ` от ` +
          (task.creator?.name || "Системы"),
        actions: [
          {
            label: "Открыть",
            color: "primary",
            noCaps: true,
            handler: () => {
              $bus.emit("tasks/showDialog", task.id);
            },
          },
        ],
      });
    }
  });

  if (!store.user.verified) {
    $bus.emit("emailVerify");
    return;
  }

  if (!store.companies.length) {
    welcomeDialog();
    return;
  }

  checkInvites();

  $bus.on("upload", (src) => {
    uploadSrc.value = src;
    filesRef.value?.click();
  });
});

const sendFiles = async () => {
  const fd = new FormData();
  if (!filesRef.value.files.length) return false;
  for (let file of filesRef.value.files) {
    fd.append("files[]", file);
  }
  try {
    const notification = $q.notify({
      group: false,
      timeout: 0,
      spinner: true,
      message: "Загрузка файлов...",
      caption: "0%",
    });
    await axios.post(uploadSrc.value, fd, {
      onUploadProgress: ({ loaded, total }) => {
        const percentage = Math.floor((loaded * 100) / total);
        notification({
          caption: `${percentage}%`,
        });
      },
    });
    notification({
      icon: "done",
      spinner: false,
      message: "Загрузка завершена!",
      color: "positive",
      timeout: 2500,
    });
  } catch (e) {
    //
  } finally {
    filesRef.value.value = "";
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", escHandler);
});
</script>
