<template>
  <q-card flat>
    <q-toolbar class="bg-positive text-white">
      <q-toolbar-title>{{ phone }}</q-toolbar-title>
      <q-btn dense flat icon="close" @click="$emit('close')"></q-btn>
    </q-toolbar>
    <q-card-section>
      <q-scroll-area
        :horizontal-thumb-style="{ opacity: 0 }"
        style="height: calc(100vh - 85px)"
      >
        <q-chat-message
          v-for="message in messages"
          :key="message.id"
          :name="message.name"
          :sent="message.sent"
          :stamp="date.formatDate(message.created_at, 'DD.MM.YYYY')"
        >
          <q-img
            :src="message.data"
            v-if="message.message_type == 'image'"
          ></q-img>
          <span v-else-if="message.message_type == 'audio'">
            <audioPlayer :src="message.data"></audioPlayer>
          </span>
          <span v-else>{{ message.data }}</span>
        </q-chat-message>
      </q-scroll-area>
    </q-card-section>
    <q-input
      standout
      autogrow
      type="textarea"
      label="Сообщение"
      resize="off"
      @keypress.enter="sendMessage"
      v-model="text"
      class="absolute-bottom-left full-width"
    >
      <template v-slot:prepend>
        <q-btn
          dense
          flat
          icon="mic"
          @mousedown="record"
          @mouseup="stopRecord"
        ></q-btn>
        <q-btn
          dense
          flat
          icon="attach_file"
          @click.stop.prevent="$refs.file.click()"
        ></q-btn>
      </template>
      <template v-slot:append>
        <q-btn flat round icon="send" @click="sendMessage"></q-btn>
      </template>
    </q-input>
    <input type="file" class="hidden" ref="file" @change="sendFile" />
  </q-card>
</template>

<script>
import { axios } from "@/services";
import audioPlayer from "../audioPlayer.vue";

export default {
  components: { audioPlayer },
  props: {
    id: Number,
    phone: String,
  },
  data() {
    return {
      messages: [],
      text: "",
      recorder: false,
      chunks: [],
      recordTime: 0,
    };
  },
  async created() {
    this.loadMessages();
  },
  methods: {
    async loadMessages() {
      try {
        const params = { phone: this.phone };
        const { data } = await axios.get(`integrations/${this.id}/messages`, {
          params,
        });
        this.messages = data;
      } catch (e) {
        //
      }
    },
    async sendMessage(e) {
      if (!this.text || e.shiftKey) {
        return;
      }
      try {
        const { data } = await axios.post(`integrations/${this.id}/messages`, {
          type: "text",
          phone: this.phone,
          data: this.text,
        });
        this.messages.push(data);
        this.text = "";
      } catch (e) {
        //
      }
    },
    async sendFile() {
      const file = this.$refs.file.files[0];
      if (!file) {
        return;
      }
      try {
        let type = "document";
        if (file.type.indexOf("image") > -1) {
          type = "image";
        } else if (file.type.indexOf("audio") > -1) {
          type = "audio";
        }
        const fd = new FormData();
        fd.append("type", type);
        fd.append("data", file);
        fd.append("phone", this.phone);
        const { data } = await axios.post(
          `integrations/${this.id}/messages`,
          fd,
        );
        this.messages.push(data);
      } catch (e) {
        //
      } finally {
        this.$refs.file.value = "";
      }
    },
    record() {
      if (this.recorder) {
        this.startRecord();
      } else {
        window.navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then((stream) => {
            const recorder = new MediaRecorder(stream);
            recorder.ondataavailable = (e) => {
              this.chunks.push(e.data);
            };
            recorder.onstop = async () => {
              let blob = new Blob(this.chunks, {
                type: "audio/mp3",
              });
              blob = blob.slice(0, blob.size, "audio/mp3");
              if (await this.confirm("Отправить запись?")) {
                const fd = new FormData();
                fd.append("type", "audio");
                fd.append("data", blob, "audio.mp3");
                fd.append("phone", this.phone);
                const { data } = await axios.post(
                  `integrations/${this.id}/messages`,
                  fd,
                );
                this.messages.push(data);
              }
            };
            this.recorder = recorder;
            this.startRecord();
          })
          .catch(() => {
            this.$q.notify("Не удалось получить доступ к микрофону");
          });
      }
    },
    startRecord() {
      this.chunks = [];
      this.recordTime = 0;
      this.recorder.start();
    },
    stopRecord() {
      this.recorder.stop();
    },
  },
};
</script>
