import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import { bus } from "@/bus";
import ApexCharts from "vue3-apexcharts";
import quasarUserOptions from "./quasar-user-options";
import "@quasar/extras/animate/fadeInLeft.css";
import "@quasar/extras/animate/fadeOut.css";

window.Pusher = require("pusher-js");
const app = createApp(App);
const pinia = createPinia();

app.provide("bus", bus);
app
  .use(pinia)
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(ApexCharts)
  .mount("#app");
