<template>
  <q-dialog
    v-model="isOpen"
    persistent
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="full-height" style="width: 400px">
      <q-toolbar class="text-white bg-teal">
        <q-toolbar-title>Настройка полей</q-toolbar-title>
        <q-btn dense flat icon="close" v-close-popup></q-btn>
      </q-toolbar>
      <q-card-section>
        <q-btn
          flat
          no-caps
          color="positive"
          icon="add"
          label="Добавить новое поле"
          @click="edit({})"
        />
        <q-scroll-area class="h-l3">
          <draggable
            class="q-list q-list--separator"
            v-model="items"
            v-bind="dragOptions"
            group="actions"
            item-key="id"
            @change="change"
          >
            <template #item="{ element }">
              <q-item>
                <q-item-section>{{ element.name }}</q-item-section>
                <q-item-section top side>
                  <div class="q-gutter-xs">
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      round
                      flat
                      dense
                      no-caps
                      color="primary"
                      icon="edit"
                      @click="edit(element)"
                    />
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      round
                      flat
                      dense
                      no-caps
                      color="negative"
                      icon="delete"
                      @click="destroy(element)"
                    />
                  </div>
                </q-item-section>
              </q-item>
            </template>
          </draggable>
        </q-scroll-area>
        <q-dialog v-model="modal" persistent>
          <q-card flat style="width: 300px">
            <q-toolbar class="bg-positive text-white">
              <span>
                <span v-if="model.id">{{ model.name }}</span>
                <span v-else>Новое поле</span>
              </span>
              <q-space></q-space>
              <q-btn flat no-caps v-close-popup round dense icon="close" />
            </q-toolbar>
            <q-card-section class="q-gutter-y-sm">
              <q-input
                dense
                standout
                autofocus
                label="Название"
                v-model="model.name"
              />
              <q-select
                dense
                standout
                options-dense
                map-options
                emit-value
                label="Тип"
                :options="types"
                v-model="model.type"
                :disable="Boolean(model.id)"
              />
              <div v-if="['select', 'multiselect'].indexOf(model.type) > -1">
                <draggable
                  v-model="model.list"
                  v-bind="dragOptions"
                  group="list"
                  handle=".move-handler"
                >
                  <template #item="{ index }">
                    <div>
                      <q-input
                        dense
                        standout
                        v-model="model.list[index]"
                        class="q-mt-sm"
                        :label="`Элемент списка ${index + 1}`"
                      >
                        <template v-slot:append>
                          <q-btn
                            round
                            flat
                            no-caps
                            size="sm"
                            icon="delete"
                            color="negative"
                            @click="model.list.splice(index, 1)"
                          />
                        </template>
                        <template v-slot:after>
                          <q-icon
                            name="height"
                            color="blue"
                            class="move-handler"
                          />
                        </template>
                      </q-input>
                    </div>
                  </template>
                </draggable>
                <q-btn
                  dense
                  no-caps
                  color="positive"
                  class="full-width q-mt-sm"
                  label="Добавить значение"
                  @click="model.list.push('')"
                />
              </div>
              <EmployersSelector
                v-model="model.employers"
                label="Выберите сотрудников"
                multiple
                hint="Это поле будет скрыто для выбранных сотрудников"
              />
            </q-card-section>
            <q-card-actions align="right">
              <q-btn no-caps color="primary" label="Отмена" v-close-popup />
              <q-btn no-caps color="positive" label="Сохранить" @click="save" />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import draggable from "vuedraggable";
import EmployersSelector from "@/components/selectors/employers-selector.vue";
import { axios } from "@/services";
import { useStore } from "@/store";
import { useQuasar } from "quasar";
import { confirm } from "@/helpers";
import { ref, onMounted, onBeforeUnmount, inject } from "vue";
import { dragOptions } from "@/constants";

const model = ref({});
const modal = ref(false);
const types = ref([
  { value: "phone", label: "Телефон" },
  { value: "date", label: "Дата" },
  { value: "string", label: "Строка" },
  { value: "link", label: "Ссылка" },
  { value: "text", label: "Текст" },
  { value: "number", label: "Число" },
  { value: "money", label: "Денежное" },
  { value: "file", label: "Файлы" },
  { value: "boolean", label: "Чекбокс" },
  { value: "select", label: "Список" },
  { value: "multiselect", label: "Множественный список" },
]);
const isOpen = ref(false);
const store = useStore();
const items = ref([]);
let modelType = "";
const $q = useQuasar();
const $bus = inject("bus");

const edit = async (item) => {
  if (item.id) {
    const { data } = await axios.get(`fields/${item.id}`);
    model.value = data;
  } else {
    item.model = modelType;
    model.value = item;
  }
  if (!model.value.list) model.value.list = [];
  modal.value = true;
};

const save = async () => {
  try {
    const requestData = {
      name: model.value.name,
      type: model.value.type,
      list: model.value.list,
      model: modelType,
      employers: model.value.employers?.map((item) => item.id),
    };
    if (model.value.id) {
      await axios.put(`fields/${model.value.id}`, requestData);
    } else {
      const { data } = await axios.post("fields", requestData);
      store.fields.push(data);
      items.value.push(data);
    }
    $q.notify("Поле успешно сохранено");
    model.value = {};
    modal.value = false;
  } catch (e) {
    //
  }
};

const change = async (evt) => {
  if (evt.moved) {
    try {
      const { newIndex, element } = evt.moved;
      await axios.post(`fields/${element.id}/move`, { sort: newIndex });
    } catch (e) {
      //
    }
  }
};

const destroy = async (item) => {
  if (!(await confirm())) {
    return false;
  }

  try {
    await axios.delete(`fields/${item.id}`);
    store.fields = store.fields.filter(({ id }) => id != item.id);
    items.value = items.value.filter(({ id }) => id != item.id);
    $q.notify("Поле успешно удалено");
  } catch (e) {
    //
  }
};

const onCallDialog = (data) => {
  modelType = data;
  items.value = store.fieldsByModel(modelType);
  isOpen.value = true;
};

onMounted(() => {
  $bus.on("fields/showDialog", onCallDialog);
});

onBeforeUnmount(() => {
  $bus.off("fields/showDialog", onCallDialog);
});
</script>
