<template>
  <div>
    <RouterView />
    <q-dialog v-model="terms">
      <q-card>
        <q-card-section>
          <div class="text-h5">Правила и соглашения</div>
        </q-card-section>
        <q-card-section>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate
            sit mollitia, omnis quas nam ipsum. Cumque ea delectus hic nobis
            ipsam quae distinctio ullam. Molestiae blanditiis incidunt
            voluptatem asperiores nam.
          </p>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            dense
            color="positive"
            no-caps
            label="Понятно"
            @click="terms = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script setup>
import { inject, onMounted, ref } from "vue";

const terms = ref(false);
const $bus = inject("bus");

onMounted(() => {
  $bus.on("open-terms", () => {
    terms.value = true;
  });
});
</script>
