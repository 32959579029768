<template>
  <q-layout view="lHr lpR lFr">
    <q-page-container>
      <q-page
        :style="{ backgroundImage: `url(${bg})` }"
        class="img-overlay auth"
      >
        <img
          src="@/assets/rp.svg"
          width="128px"
          class="auth-logo"
          :class="{ active: isLogoActive }"
        />
        <div class="absolute-full text-subtitle2 flex flex-center">
          <transition
            enter-active-class="animated fadeInLeft"
            leave-active-class="animated fadeOut"
          >
            <RouterView />
          </transition>
        </div>
        <div class="flex rights justify-between items-center">
          <span clas="text-caption">
            &copy; ROSTPOINT 2023. Все права защищены. ООО "ТОЧКА РОСТА" ИНН
            9731097805
          </span>
          <span class="q-gutter-x-md">
            <q-btn flat dense no-caps @click.prevent="$bus.emit('open-terms')">
              Правила и соглашения
            </q-btn>
            <q-btn flat dense no-caps @click.prevent="$bus.emit('open-terms')">
              Помощь
            </q-btn>
          </span>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { useQuasar } from "quasar";
import { ref } from "vue";

const bg = require("@/assets/auth_bg.png");
const $q = useQuasar();
const isLogoActive = ref(false);
$q.dark.set(false);

setTimeout(() => {
  isLogoActive.value = true;
}, 500);
</script>
