<template>
  <q-btn flat>
    <div>{{ h }}</div>
    <div class="pulse">:</div>
    <div>{{ m }}</div>
    <q-popup-proxy>
      <q-date v-model="dateModel"></q-date>
    </q-popup-proxy>
  </q-btn>
</template>

<script setup>
import { date } from "quasar";
import { onBeforeUnmount, onMounted, ref } from "vue";

const h = ref("00");
const m = ref("00");
const interval = null;
const dateModel = date.formatDate(new Date(), "YYYY/MM/DD");

onMounted(() => {
  updateTimer();
  setInterval(updateTimer, 1000);
});

onBeforeUnmount(() => {
  clearInterval(interval);
});

const updateTimer = () => {
  const now = new Date();
  h.value = date.formatDate(now, "HH");
  m.value = date.formatDate(now, "mm");
};
</script>
