<template>
  <q-card class="cursor-pointer">
    <slot></slot>
    <q-item :clickable="!selectable" class="q-px-sm">
      <q-item-section>
        <q-item-label caption class="flex justify-between items-center">
          <small>
            <q-icon
              name="check_circle"
              color="positive"
              size="12px"
              v-if="item.completed_at"
            >
              <q-tooltip class="text-center">
                Сделка завершена <br />
                {{ date.formatDate(item.completed_at, "DD.MM.YYYY HH:mm") }}
              </q-tooltip>
            </q-icon>
            {{ date.formatDate(item.created_at, "DD.MM.YYYY") }}
          </small>
          <q-badge color="positive" :label="`#${item.number}`" />
        </q-item-label>
        <q-item-label v-if="item.name" class="ellipsis">
          {{ item.name }}
        </q-item-label>
        <q-item-label class="flex justify-between items-end">
          <div>
            <div v-if="item.client" class="no-wrap">
              <span>{{ item.client.name }}</span>
            </div>
            <div v-for="field in fields" :key="field.id">
              <cardSlot
                :fv="item.fvs.find((fv) => fv.field_id == field.id)"
                :field="field"
              ></cardSlot>
            </div>
          </div>
          <div class="text-caption text-negative" v-if="diff">
            {{ diff }} дн.
          </div>
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-card>
</template>

<script setup>
import { date } from "quasar";
import cardSlot from "./cardSlot.vue";
import { computed, toRefs, defineProps } from "vue";

const props = defineProps({
  item: Object,
  fields: {
    type: Array,
    default: () => [],
  },
});
const { item, fields } = toRefs(props);

const diff = computed(() =>
  date.getDateDiff(Date.now(), props.item.updated_at, "days"),
);
</script>
