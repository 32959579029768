<template>
  <q-field dense borderless class="full-width">
    <template v-slot:before>
      <q-btn
        dense
        flat
        round
        icon="play_arrow"
        color="blue"
        @click="play"
      ></q-btn>
    </template>
    <template v-slot:control>
      <q-range
        v-model="value"
        drag-range
        drag-only-range
        color="blue"
        :min="0"
        :max="100"
      />
    </template>
  </q-field>
</template>

<script setup>
import { onMounted, ref, defineProps } from "vue";

const props = defineProps({
  src: String,
});
const value = ref({
  min: 0,
  max: 0,
});
const audio = ref(false);

onMounted(() => {
  audio.value = new Audio(props.src);
});

const play = () => {
  if (!audio.value) {
    return;
  }
  audio.value.play();
};
</script>
