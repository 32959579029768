<template>
  <q-input
    dense
    standout
    mask="#"
    reverse-fill-mask
    :model-value="props.modelValue"
    @blur="updateModelValue"
    :rules="[
      (val) =>
        store.employer.super ||
        (Number(val) <= store.employer.budget && Number(val) < maxValue) ||
        `Доступно ${store.employer.budget}`,
    ]"
    :hint="store.employer.super ? '' : `Доступно ${store.employer.budget}`"
    label="Мотивация (пойнты)"
  ></q-input>
</template>

<script setup>
import { useStore } from "@/store";
import { ref, defineEmits, defineProps } from "vue";

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
});
const emit = defineEmits(["update:modelValue"]);
const store = useStore();
const maxValue = ref(1000000);

const updateModelValue = (value) => {
  emit("update:modelValue", Number(value) || 0);
};
</script>
