<template>
  <q-list>
    <q-item class="q-mb-sm">
      <q-item-section class="text-subtitle"> В работе </q-item-section>
      <q-item-section side>
        <span class="text-h5 text-primary">
          {{ diffDate(order.created_at) }}
        </span>
      </q-item-section>
    </q-item>
    <q-item class="q-mb-sm">
      <q-item-section class="text-subtitle"> Бездействие </q-item-section>
      <q-item-section side>
        <span class="text-h5 text-primary">
          {{ diffDate(order.updated_at) }}
        </span>
      </q-item-section>
    </q-item>
    <q-item class="q-mb-sm">
      <q-item-section class="text-subtitle"> Создано задач </q-item-section>
      <q-item-section side>
        <span class="text-h5 text-primary">
          {{ order.tasks }}
        </span>
      </q-item-section>
    </q-item>
    <q-item class="q-mb-sm">
      <q-item-section class="text-subtitle"> Выполнено задач </q-item-section>
      <q-item-section side>
        <span class="text-h5 text-primary">
          {{ order.tasks_done }}
        </span>
      </q-item-section>
    </q-item>
    <q-item class="q-mb-sm">
      <q-item-section class="text-subtitle">
        Количество сообщений
      </q-item-section>
      <q-item-section side>
        <span class="text-h5 text-primary">
          {{ order.messages }}
        </span>
      </q-item-section>
    </q-item>
    <q-item class="q-mb-sm">
      <q-item-section class="text-subtitle"> Количество файлов </q-item-section>
      <q-item-section side>
        <span class="text-h5 text-primary">
          {{ order.files }}
        </span>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script setup>
import { axios } from "@/services";
import { date } from "quasar";
import { computed, onMounted, ref, defineProps } from "vue";

const props = defineProps({
  id: String,
});

const order = ref({});

onMounted(async () => {
  const { data } = await axios.get(`orders/${props.id}/shortInfo`);
  order.value = data;
});

const diffDate = computed(() => {
  return (value) => {
    const now = new Date();
    const diffH = date.getDateDiff(now, value, "hours");
    if (diffH > 24) {
      return date.getDateDiff(now, value, "days") + " дн";
    }

    return diffH + " ч";
  };
});
</script>
