<template>
  <q-table
    flat
    :rows="items"
    hide-pagination
    :rows-per-page-options="[0]"
    wrap-cells
    :columns="[
      { name: 'expand' },
      { name: 'name', label: 'Наименование', field: 'name', align: 'left' },
      {
        name: 'quantity',
        label: 'Количество',
        field: 'quantity',
        align: 'left',
      },
      { name: 'price', label: 'Цена', field: 'price', align: 'left' },
      { name: 'actions', align: 'right' },
    ]"
  >
    <template v-slot:body="props">
      <q-tr
        :props="props"
        class="cursor-pointer"
        @click="toggleExpand(props.row)"
      >
        <q-td auto-width>
          <q-icon
            size="18px"
            :name="
              expand == props.row.id
                ? 'keyboard_arrow_up'
                : 'keyboard_arrow_down'
            "
            v-if="props.row.stages_count"
          />
        </q-td>
        <q-td key="name">
          <div>{{ props.row.name }}</div>
          <span v-if="props.row.group" class="text-caption">
            {{ props.row.group.name }}
          </span>
        </q-td>
        <q-td key="quantity">{{ props.row.quantity }}</q-td>
        <q-td key="price">{{ props.row.price }}</q-td>
        <q-td key="actions" style="width: 80px">
          <q-btn
            size="sm"
            dense
            flat
            icon="edit"
            color="primary"
            @click.stop.prevent="edit(props.row)"
          ></q-btn>
          <q-btn
            size="sm"
            dense
            flat
            icon="delete"
            color="negative"
            @click.stop.prevent="destroy(props.row)"
          ></q-btn>
        </q-td>
      </q-tr>
      <q-tr v-if="expand == props.row.id" :props="props">
        <q-td colspan="100%" no-hover>
          <itemStages :id="expand"></itemStages>
        </q-td>
      </q-tr>
    </template>
    <template v-slot:top>
      <div class="q-gutter-x-sm">
        <q-btn
          dense
          no-caps
          color="positive"
          label="Добавить"
          icon="add"
          @click="edit({})"
        />
        <q-btn dense no-caps color="positive" label="Импорт">
          <q-menu>
            <q-list dense>
              <q-item clickable v-close-popup>
                <q-item-section @click="downloadTemplate">
                  Скачать образец
                </q-item-section>
              </q-item>
              <q-item clickable @click="$refs.file.click()" v-close-popup>
                <q-item-section> Загрузить файл </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="upload"
            ref="file"
            class="hidden"
          />
        </q-btn>
      </div>
    </template>
    <template v-slot:bottom-row>
      <q-tr v-show="items.length">
        <q-th></q-th>
        <q-th colspan="2" align="left"> Итого </q-th>
        <q-th colspan="2" align="left">{{ total }}</q-th>
      </q-tr>
    </template>
  </q-table>
  <q-dialog v-model="showModelDialog">
    <q-card flat style="width: 350px">
      <q-card-section class="q-gutter-y-xs">
        <q-input
          dense
          standout
          v-model="model.name"
          autofocus
          label="Наименование товара/услуги"
        ></q-input>
        <div class="row q-col-gutter-x-xs">
          <div class="col">
            <q-input
              dense
              standout
              v-model="model.price"
              mask="#.#"
              reverse-fill-mask
              label="Цена за ед."
            ></q-input>
          </div>
          <div class="col">
            <q-input
              dense
              standout
              v-model="model.quantity"
              mask="#"
              reverse-fill-mask
              label="Количество"
            ></q-input>
          </div>
        </div>
        <q-select
          dense
          standout
          v-model="model.order_stage_group_id"
          options-dense
          :options="groups"
          option-label="name"
          option-value="id"
          emit-value
          map-options
          label="Выберите шаблон"
        ></q-select>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn no-caps color="primary" label="Отмена" v-close-popup />
        <q-btn no-caps color="positive" label="Добавить" @click="save" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import itemStages from "./itemStages.vue";
import { axios } from "@/services";
import { computed, onMounted, ref, defineProps } from "vue";
import { useQuasar } from "quasar";
import { confirm } from "@/helpers";

const props = defineProps({
  id: String,
});

const items = ref([]);
const model = ref({});
const groups = ref([]);
const expand = ref(null);
const showModelDialog = ref(false);
const fileRef = ref();
const $q = useQuasar();

const total = computed(() => {
  const result = items.value.reduce(
    (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
    0,
  );
  return Number(result).toFixed(2);
});

const upload = async () => {
  const file = fileRef.value.files[0];
  const fd = new FormData();
  fd.append("file", file);
  try {
    const { data } = await axios.post(`items/import/${props.id}`, fd);
    items.value = data;
  } catch (e) {
    $q.notify({
      color: "negative",
      message:
        "Не удалось импортировать файл, проверьте правильность его заполнения",
    });
  } finally {
    fileRef.value.value = "";
  }
};

const loadItems = async () => {
  const { data } = await axios.get(`orders/${props.id}/items`);
  items.value = data;
};

const loadGroups = async () => {
  const { data } = await axios.get("order_stage_groups");
  groups.value = data;
};

const edit = (item) => {
  expand.value = null;
  if (!item.id) {
    model.value = {
      quantity: 1,
      price: 0,
      order_id: props.id,
    };
  } else {
    model.value = item;
  }
  showModelDialog.value = true;
};

const save = async () => {
  const { value: requestData } = model;
  try {
    if (requestData.id) {
      const { data } = await axios.put(`items/${requestData.id}`, requestData);
      const index = items.value.findIndex(({ id }) => id == requestData.id);
      if (index > -1) {
        items.value.splice(index, 1, data);
      }
    } else {
      const { data } = await axios.post("items", requestData);
      items.value.push(data);
    }
    showModelDialog.value = false;
  } catch (e) {
    //
  }
};

const destroy = async (item) => {
  if (!(await confirm())) {
    return;
  }
  try {
    await axios.delete(`items/${item.id}`);
    items.value = items.value.filter(({ id }) => item.id != id);
  } catch (e) {
    //
  }
};

const toggleExpand = async ({ id, stages_count }) => {
  if (!stages_count || id == expand.value) {
    expand.value = null;
    return;
  }
  expand.value = id;
};

const downloadTemplate = () => {
  window.open(`${process.env.VUE_APP_URL}/templates/orderitem`);
};

onMounted(() => {
  loadItems();
  loadGroups();
});
</script>
