<template>
  <q-dialog
    v-model="isOpen"
    persistent
    maximized
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-layout view="hHh lpR lfr" container>
      <q-drawer
        v-model="integrationDialog"
        behavior="mobile"
        :width="350"
        side="right"
        overlay
      >
        <Wazzup
          v-if="integrationDialog"
          @close="integrationDialog = false"
          :id="integration_id"
          :phone="phone"
        ></Wazzup>
      </q-drawer>
      <q-header>
        <q-toolbar>
          <div class="flex q-gutter-x-sm items-center">
            <q-toolbar-title>
              <span v-if="order.order_at">Заказ</span>
              <span v-else>Сделка</span>
            </q-toolbar-title>
            <q-badge color="blue">#{{ order.number }}</q-badge>
            <q-badge color="positive" v-if="order.completed_at">
              Завершена
            </q-badge>
            <span class="text-subtitle2">
              <span v-if="order.order_at">
                {{ date.formatDate(order.order_at, "DD.MM.YYYY HH:mm") }}
              </span>
              <span v-else>
                {{ date.formatDate(order.created_at, "DD.MM.YYYY HH:mm") }}
              </span>
            </span>
          </div>
          <q-space />
          <q-btn dense flat no-caps icon="close" @click="close">
            <q-tooltip>Закрыть</q-tooltip>
          </q-btn>
        </q-toolbar>
      </q-header>
      <q-page-container class="bg-page">
        <q-page class="row q-col-gutter-x-sm q-pt-sm">
          <div class="col-12 col-md-3 flex items-stretch">
            <q-card class="full-width relative-position">
              <q-toolbar>
                <q-toolbar-title> Информация </q-toolbar-title>
                <div class="q-gutter-xs">
                  <q-btn
                    no-caps
                    flat
                    icon="bar_chart"
                    color="positive"
                    label="Статистика"
                    @click="showStat = true"
                  ></q-btn>
                  <q-btn no-caps icon="read_more" color="primary" dense>
                    <q-menu dense>
                      <q-list dense>
                        <q-item clickable>
                          <q-item-section side>
                            <q-icon
                              name="share"
                              size="xs"
                              color="primary"
                            ></q-icon>
                          </q-item-section>
                          <q-item-section> Поделиться </q-item-section>
                        </q-item>
                        <q-item
                          clickable
                          @click="complete"
                          v-close-popup
                          v-if="!order.completed_at"
                        >
                          <q-item-section side>
                            <q-icon
                              name="check"
                              size="xs"
                              color="positive"
                            ></q-icon>
                          </q-item-section>
                          <q-item-section>Завершить</q-item-section>
                        </q-item>
                        <q-item
                          clickable
                          @click="makeOrder"
                          v-close-popup
                          v-if="!order.order_at && store.can('orders', 1)"
                        >
                          <q-item-section side>
                            <q-icon
                              name="currency_ruble"
                              size="xs"
                              color="positive"
                            ></q-icon>
                          </q-item-section>
                          <q-item-section>Создать заказ</q-item-section>
                        </q-item>
                        <q-item
                          clickable
                          @click="destroy"
                          v-close-popup
                          v-if="store.can('orders', 2)"
                        >
                          <q-item-section side>
                            <q-icon
                              name="delete"
                              size="xs"
                              color="negative"
                            ></q-icon>
                          </q-item-section>
                          <q-item-section> Удалить </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </q-toolbar>
              <q-scroll-area
                :horizontal-thumb-style="{ opacity: 0 }"
                style="height: calc(100vh - 100px)"
              >
                <q-card-section class="q-gutter-y-sm" v-if="order.id">
                  <q-field label="Создано" dense standout stack-label>
                    <template v-slot:control>
                      {{
                        order.creator?.full_name ||
                        order.source?.name ||
                        "Не указано"
                      }}
                    </template>
                  </q-field>
                  <q-input
                    dense
                    standout
                    v-model="order.name"
                    label="Название"
                    debounce="800"
                    @blur="changeName"
                  ></q-input>
                  <BoardsSelector
                    :modelValue="order.board_id"
                    @update:modelValue="move"
                    class="full-width"
                    :disable="!order.id"
                    v-if="!order.order_at"
                  />
                  <EmployersSelector
                    class="full-width"
                    v-model="order.employer"
                    label="Ответственный"
                    @update:modelValue="changeEmployer"
                  />
                  <ClientSelector
                    v-model="order.client"
                    @update:modelValue="changeClient"
                    integrations
                    map-options
                    contacts
                    info
                  />
                  <div class="flex justify-between items-center">
                    <div>Дополнительные поля</div>
                    <q-btn
                      flat
                      noCaps
                      color="positive"
                      icon="settings"
                      size="sm"
                      @click="$bus.emit('fields/showDialog', 'orders')"
                      v-if="store.can('leads', 3) || store.can('orders', 3)"
                    ></q-btn>
                  </div>
                  <Field
                    :field="field"
                    :modelId="order.id"
                    v-model="fieldValues[field.id]"
                    v-for="field in visibleFields"
                    :key="field.id"
                    :error="
                      !Boolean(fieldValues[field.id]) &&
                      Boolean(requiredFields[field.id])
                    "
                  />
                </q-card-section>
                <q-card-section v-else>
                  <q-skeleton type="text" class="text-subtitle1" />
                  <q-skeleton type="text" width="50%" class="text-subtitle1" />
                  <q-skeleton type="text" class="text-caption" />
                  <q-separator></q-separator>
                  <q-skeleton type="text" class="text-subtitle1" />
                  <q-skeleton type="text" width="50%" class="text-subtitle1" />
                  <q-skeleton type="text" class="text-caption" />
                </q-card-section>
              </q-scroll-area>
              <q-card
                class="showStat absolute-top-left full-width"
                :class="{ active: showStat }"
              >
                <q-toolbar>
                  <q-toolbar-title>Статистика</q-toolbar-title>
                  <q-btn
                    flat
                    dense
                    icon="close"
                    @click="showStat = false"
                  ></q-btn>
                </q-toolbar>
                <q-card-section>
                  <ShortInfo v-if="showStat" :id="order.id"></ShortInfo>
                </q-card-section>
              </q-card>
            </q-card>
          </div>
          <div class="col-12 col-md-6 flex items-stretch">
            <q-card flat class="full-width">
              <q-tabs
                v-model="tab"
                align="justify"
                no-caps
                indicator-color="positive"
              >
                <q-tab label="История" name="messages"></q-tab>
                <q-tab label="Состав заказа" name="items"></q-tab>
              </q-tabs>
              <q-tab-panels v-model="tab">
                <q-tab-panel name="messages" class="q-pa-none">
                  <MessageList
                    toolbar
                    v-if="order.id"
                    :id="order.id"
                    model="orders"
                    title="История"
                    height="calc(100vh - 210px)"
                  />
                </q-tab-panel>
                <q-tab-panel name="items">
                  <OrderItems v-if="order.id" :id="order.id"></OrderItems>
                </q-tab-panel>
              </q-tab-panels>
            </q-card>
          </div>
          <div class="col-12 col-md-3 flex items-stretch">
            <TaskList
              :id="order.id"
              :link="`orders/${order.id}/tasks`"
              v-if="order.id"
            >
              <q-btn
                dense
                color="positive"
                icon="add"
                @click="$bus.emit('tasks/showDialog', null, { order })"
              >
                <q-tooltip>Добавить задачу</q-tooltip>
              </q-btn>
            </TaskList>
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
  </q-dialog>
</template>

<script setup>
import { axios } from "@/services";
import ClientSelector from "@/components/selectors/client-selector.vue";
import Field from "@/components/fields/field.vue";
import EmployersSelector from "@/components/selectors/employers-selector.vue";
import ShortInfo from "@/views/orders/shortInfo.vue";
import MessageList from "@/components/layout/messageList.vue";
import TaskList from "@/components/layout/taskList.vue";
import Wazzup from "@/components/integrations/wazzup.vue";
import OrderItems from "@/views/orders/items.vue";
import BoardsSelector from "@/components/selectors/boards-selector-drop-down.vue";
import { inject, onBeforeUnmount, onMounted, ref } from "vue";
import { confirm } from "@/helpers";
import { date, useQuasar } from "quasar";
import { useStore } from "@/store";

const order = ref({});
const fieldValues = ref({});
const visibleFields = ref([]);
const requiredFields = ref({});
const tab = ref("messages");
const isOpen = ref(false);
const showStat = ref(false);
const integration_id = ref(false);
const integrationDialog = ref(false);
const phone = ref("");
const $bus = inject("bus");
const store = useStore();
const $q = useQuasar();

const onIntegrationsOpen = ({ id, phone }) => {
  integration_id.value = id;
  phone.value = phone;
  integrationDialog.value = true;
};
const onOrdersDestroy = (order) => {
  if (order.id == order.value?.id) {
    $q.notify("Сделка удалена");
    close();
  }
};
const onFieldUpdate = (data) => {
  if (data.model_id == order.value?.id) {
    if (data.value) {
      fieldValues.value[data.field_id] = data.value[data.value.type];
    } else {
      delete fieldValues.value[data.field_id];
    }
  }
};
const onOrdersUpdate = (data) => {
  if (data.id == order.value?.id) {
    if (data.board_id && data.board_id != order.value.board_id) {
      loadFields();
    }
    order.value = data;
  }
};
const loadOrder = async (id) => {
  if (!id) {
    return;
  }
  try {
    const { data } = await axios.get(`orders/${id}`);
    fieldValues.value = data.fvs.reduce((acc, item) => {
      acc[item.field_id] = item[item.type];
      return acc;
    }, {});
    order.value = data;
    isOpen.value = true;
    loadFields();
  } catch (e) {
    //
  }
};

const close = () => {
  order.value = {};
  fieldValues.value = {};
  visibleFields.value = {};
  requiredFields.value = {};
  isOpen.value = false;
};

const loadFields = async () => {
  if (!order.value) {
    return;
  }
  try {
    const { data } = await axios.get(`orders/${order.value.id}/fields`);
    visibleFields.value = data.visibleFields;
    requiredFields.value =
      data.requiredFields?.reduce((acc, item) => {
        acc[item.id] = true;
        return acc;
      }, {}) || {};
  } catch (e) {
    //
  }
};

const destroy = async () => {
  if (!(await confirm())) {
    return false;
  }
  try {
    await axios.delete(`orders/${order.value.id}`);
    $q.notify("Сделка успешно удалена");
    close();
  } catch (e) {
    //
  }
};

const move = async (board_id) => {
  try {
    await axios.post(`orders/${order.value.id}/move`, {
      board_id,
    });
    $q.notify("Сделка успешно перемещена");
  } catch (e) {
    //
  }
};

const changeClient = async () => {
  try {
    await axios.post(`orders/${order.value.id}/changeClient`, {
      client_id: order.value.client?.id,
    });
  } catch (e) {
    //
  }
};

const changeEmployer = async () => {
  try {
    await axios.post(`orders/${order.value.id}/changeEmployer`, {
      employer_id: order.value.employer?.id,
    });
  } catch (e) {
    //
  }
};

const changeName = async () => {
  const name = order.value.name;
  try {
    await axios.post(`orders/${order.value.id}/changeName`, {
      name,
    });
  } catch (e) {
    //
  }
};

const complete = async () => {
  if (!(await confirm("Подтвердите завершение сделки"))) {
    return;
  }
  try {
    await axios.post(`orders/${order.value.id}/complete`);
    order.value.completed_at = true;
  } catch (e) {
    //
  }
};

const makeOrder = async () => {
  if (
    !(await confirm(
      "Вы собираетесь переместить сделку в модуль заказы для дальнейшей работы. Подтверждаете действие?",
    ))
  ) {
    return;
  }
  try {
    await axios.post(`orders/${order.value.id}/makeOrder`);
    order.value.order_at = new Date();
    $q.notify("Заказ успешно создан");
  } catch (e) {
    //
  }
};

onMounted(() => {
  $bus.on("orders/showDialog", loadOrder);
  $bus.on("integrations/open", onIntegrationsOpen);
  $bus.on("orders/update", onOrdersUpdate);
  $bus.on("fieldValue/update", onFieldUpdate);
  $bus.on("orders/destroy", onOrdersDestroy);
});

onBeforeUnmount(() => {
  $bus.off("orders/showDialog", loadOrder);
  $bus.off("orders/update", onOrdersUpdate);
  $bus.off("orders/destroy", onOrdersDestroy);
  $bus.off("fieldValue/update", onFieldUpdate);
  $bus.off("integrations/open", onIntegrationsOpen);
});
</script>
<style lang="scss">
.showStat {
  height: 100%;
  max-height: 0;
  transition: 0.5s all ease-in-out;
  overflow: hidden;
  &.active {
    max-height: 1000px;
    overflow: auto;
  }
}
</style>
@/services/axios
