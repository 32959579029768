<template>
  <q-dialog
    v-model="isOpen"
    persistent
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="full-height" style="width: 400px">
      <q-toolbar class="text-white bg-teal">
        <q-toolbar-title>Настройка записей</q-toolbar-title>
        <q-btn dense flat icon="close" v-close-popup></q-btn>
      </q-toolbar>
      <q-card-section>
        <q-btn
          flat
          no-caps
          color="positive"
          icon="add"
          label="Добавить запись"
          @click="add"
        />
        <q-list>
          <q-item v-for="item in store[model]" :key="item.id">
            <q-item-section>{{ item.name }}</q-item-section>
            <q-item-section top side>
              <div class="q-gutter-xs">
                <q-btn
                  class="gt-xs"
                  size="12px"
                  round
                  flat
                  dense
                  no-caps
                  color="primary"
                  icon="edit"
                  @click="edit(item)"
                />
                <q-btn
                  class="gt-xs"
                  size="12px"
                  round
                  flat
                  dense
                  no-caps
                  color="negative"
                  icon="delete"
                  @click="destroy(item)"
                />
              </div>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { axios } from "@/services";
import { useStore } from "@/store";
import { useQuasar } from "quasar";
import { confirm, prompt } from "@/helpers";
import { ref, onMounted, onBeforeUnmount, inject } from "vue";

const isOpen = ref(false);
const store = useStore();
const $q = useQuasar();
const $bus = inject("bus");
const model = ref();

const edit = async (item) => {
  const name = await prompt("Новое название", item.name);
  if (!name) {
    return;
  }
  try {
    await axios.put(`${model.value}/${item.id}`, { name });
    item.name = name;
  } catch (e) {
    //
  }
};

const add = async () => {
  const name = await prompt("Введите название");
  if (!name) {
    return;
  }
  try {
    const { data } = await axios.post(model.value, { name });
    const { value: key } = model;
    store[key].push(data);
  } catch (e) {
    //
  }
};

const destroy = async (item) => {
  if (!(await confirm())) {
    return false;
  }

  try {
    await axios.delete(`${model.value}/${item.id}`);
    const { value: key } = model;
    store[key] = store[key].filter(({ id }) => id != item.id);
    $q.notify("Запись успешно удалена");
  } catch (e) {
    //
  }
};

const onCallDialog = (data) => {
  if (!store[data]) {
    return;
  }
  model.value = data;
  isOpen.value = true;
};

onMounted(() => {
  $bus.on("books/showDialog", onCallDialog);
});

onBeforeUnmount(() => {
  $bus.off("books/showDialog", onCallDialog);
});
</script>
