<template>
  <q-dialog
    v-model="isOpen"
    persistent
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card style="width: 350px">
      <q-toolbar class="bg-positive text-white">
        <q-toolbar-title>Добавить сделку</q-toolbar-title>
        <q-btn dense flat icon="close" @click="close"></q-btn>
      </q-toolbar>
      <q-card-section class="q-gutter-y-sm">
        <ClientSelector
          v-if="!clientId && isOpen"
          v-model="client"
          contacts
          autofocus
          createable
          map-options
        />
        <ItemSelector
          v-if="!funnelId && isOpen"
          v-model="funnel"
          model="funnels"
          label="Выберите воронку"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn no-caps color="primary" label="Отмена" @click="close" />
        <q-btn no-caps color="positive" label="Добавить" @click="save" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import ClientSelector from "@/components/selectors/client-selector.vue";
import ItemSelector from "@/components/selectors/item-selector.vue";
import { axios } from "@/services";
import { inject, ref, onMounted, onBeforeUnmount } from "vue";

const client = ref(null);
const funnel = ref(null);
const clientId = ref(null);
const funnelId = ref(null);
const $bus = inject("bus");
const isOpen = ref(false);

const save = async () => {
  try {
    const { data } = await axios.post("orders", {
      funnel_id: funnelId.value || funnel.value?.id,
      client_id: clientId.value || client.value?.id,
    });
    $bus.emit("orders/showDialog", data.id);
    isOpen.value = false;
  } catch (e) {
    //
  }
};

const close = () => {
  isOpen.value = false;
  client.value = null;
  funnel.value = null;
  funnelId.value = null;
  clientId.value = null;
};

const onCallDialog = (fid, cid) => {
  clientId.value = cid;
  funnelId.value = fid;
  isOpen.value = true;
};

onMounted(() => {
  $bus.on("orders/createDialog", onCallDialog);
});

onBeforeUnmount(() => {
  $bus.off("orders/createDialog", onCallDialog);
});
</script>
