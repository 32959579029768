import script from "./Main.vue?vue&type=script&setup=true&lang=js"
export * from "./Main.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QInnerLoading,QDrawer,QScrollArea,QList,QExpansionItem,QItemSection,QAvatar,QItemLabel,QItem,QTooltip,QIcon,QPageContainer});qInstall(script, 'directives', {Ripple});
