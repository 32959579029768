export const phoneMask = "### ###-##-##";
export const rowsPerPageOptions = [10, 25, 50, 100];
export const DEFAULT_ROWS_PER_PAGE = 25;
export const dragOptions = {
  animation: 200,
  disabled: false,
  scrollSensitivity: 400,
  forceFallback: true,
  ghostClass: "ghost",
};

export const flatColors = [
  "#2d262b",
  "#463537",
  "#655652",
  "#8d7c7d",
  "#c8b0b1",
  "#000008",
  "#162633",
  "#2b4c63",
  "#407a9a",
  "#55afd7",
  "#671f38",
  "#8a2844",
  "#c03b53",
  "#fe6469",
  "#ff9985",
  "#0876ff",
  "#ff50e4",
  "#ff82a4",
  "#ffe681",
  "#fffb8d",
  "#341c13",
  "#533221",
  "#7b5232",
  "#ac7d48",
  "#e6b563",
  "#cc374b",
  "#d55d44",
  "#e8915b",
  "#f8c180",
  "#f8de98",
  "#144a85",
  "#1779ac",
  "#72c2e0",
  "#cfefed",
  "#f8efed",
  "#542d49",
  "#733a52",
  "#a25162",
  "#e17775",
  "#ffaf8a",
  "#9974e7",
  "#fb70b9",
  "#ffab9f",
  "#fff4ac",
  "#ffffd0",
  "#00a9bd",
  "#21b57f",
  "#aebf6e",
  "#f8c694",
  "#ffcdc9",
  "#000c0f",
  "#0c252f",
  "#214754",
  "#3f7f7d",
  "#55a09e",
  "#120f15",
  "#183140",
  "#216173",
  "#2496af",
  "#20d1d8",
  "#ef736c",
  "#f09761",
  "#f1c17e",
  "#f7e8af",
  "#f5efd9",
  "#558c9e",
  "#306d6e",
  "#6f9276",
  "#ae9e87",
  "#d8c2be",
  "#9e77aa",
  "#9e557c",
  "#a58255",
  "#97a37a",
  "#55a393",
];
