<template>
  <div>
    <q-btn
      dense
      flat
      icon="attach_file"
      @click.stop.prevent="$bus.emit('upload', props.src)"
      style="z-index: 10000"
    >
      <q-tooltip>Прикрепить файлы</q-tooltip>
    </q-btn>
  </div>
</template>

<script setup>
import { defineProps, inject } from "vue";

const props = defineProps({
  src: String,
});
const $bus = inject("bus");
</script>
