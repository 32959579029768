<template>
  <q-markup-table flat dense>
    <tbody>
      <tr
        v-for="stage in stages"
        :key="stage.id"
      >
        <td
          class="no-border cursor-pointer"
          @click="$bus.emit('stageMessages/showDialog', stage)"
        >
          {{ stage.stage.name }}
        </td>
        <td class="no-border">
          <EmployersSelector
            :model-value="stage.employer"
            @update:modelValue="changeEmployer(stage, $event)"
            label="Исполнитель"
          ></EmployersSelector>
        </td>
        <td class="no-border" style="width: 120px">
          <Status :status="stage.status"></Status>
          <q-icon
            name="info"
            size="18px"
            class="q-ml-sm"
            v-if="stage.status != 'new'"
          >
            <q-tooltip>
              <div>
                Начат: {{ date.formatDate(stage.start_at, "DD.MM.YYYY") }}
              </div>
              <div v-if="stage.done_at">
                Завершен: {{ date.formatDate(stage.done_at, "DD.MM.YYYY") }}
              </div>
            </q-tooltip>
          </q-icon>
        </td>
        <td class="no-border">
          <span v-if="stage.stage.hasQuantity">
            {{ stage.complete }} / {{ orderItem.quantity }}
          </span>
        </td>
        <td class="no-border text-center" style="width: 80px">
          <q-btn
            dense
            flat
            icon="play_arrow"
            color="primary"
            @click.stop.prevent="startStage(stage)"
            v-if="stage.status == 'new' && canStart(stage)"
          ></q-btn>
          <q-btn
            dense
            flat
            icon="play_arrow"
            @click.stop.prevent="startStage(stage)"
            v-else-if="stage.status == 'new'"
            disable
          >
            <q-tooltip class="q-gutter-y-sm">
              <div>
                <div v-for="id in requires[stage.order_stage_id]" :key="id">
                  <q-badge :color="done[id] ? 'positive' : 'negative'">
                    {{ stagesMap[id] }}
                  </q-badge>
                </div>
              </div>
            </q-tooltip>
          </q-btn>
          <q-btn
            dense
            flat
            icon="check"
            color="positive"
            @click.stop.prevent="doneStage(stage)"
            v-if="stage.status == 'work'"
          ></q-btn>
        </td>
      </tr>
    </tbody>
  </q-markup-table>
</template>

<script setup>
import Status from "@/components/status.vue";
import EmployersSelector from "@/components/selectors/employers-selector.vue";
import { axios } from "@/services";
import { ref, defineProps, onMounted, computed, watch, inject } from "vue";
import { prompt } from "@/helpers";
import { date, useQuasar } from "quasar";

const props = defineProps({
  id: String,
});

const stages = ref([]);
const stagesMap = ref({});
const requires = ref({});
const done = ref({});
const orderItem = ref(null);
const $q = useQuasar();
const $bus = inject("bus");

const canStart = computed(
  () =>
    ({ order_stage_id }) =>
      !requires.value[order_stage_id]?.filter((id) => !done.value[id]).length,
);

const updateDone = () => {
  done.value = stages.value.reduce((acc, item) => {
    if (item.beDone) {
      acc[item.order_stage_id] = true;
    }
    return acc;
  }, {});
};

const loadItems = async () => {
  if (!props.id) {
    return;
  }
  try {
    const { data } = await axios.get(`items/${props.id}`);
    stages.value = data.stages;
    orderItem.value = data;
    stagesMap.value = data.stages.reduce((acc, item) => {
      acc[item.order_stage_id] = item.stage.name;
      return acc;
    }, {});
    requires.value =
      data.group?.requires?.reduce((acc, item) => {
        if (!acc[item.order_stage_id]) {
          acc[item.order_stage_id] = [];
        }
        acc[item.order_stage_id].push(item.another_stage_id);
        return acc;
      }, {}) || {};
    updateDone();
  } catch (e) {
    //
  }
};

const changeEmployer = async (stage, employer) => {
  try {
    await axios.post(`item_stages/${stage.id}/changeEmployer`, {
      employer_id: employer?.id,
    });
    stage.employer = employer;
  } catch (e) {
    //
  }
};

const doneStage = async (item) => {
  let complete = 1;
  if (item.stage.hasQuantity && orderItem.value.quantity > 1) {
    complete = await prompt("Введите количество");
    if (!complete) {
      return;
    }
    complete = Number(complete);
    if (!complete || complete > orderItem.value.quantity - item.complete) {
      $q.notify({
        message: "Введено некорректное значение",
        color: "negative",
      });
      return;
    }
  }
  try {
    const { data } = await axios.post(`item_stages/${item.id}/done`, {
      complete,
    });
    stages.value.splice(stages.value.indexOf(item), 1, data);
    updateDone();
  } catch (e) {
    if (e.response?.status == 422) {
      $q.notify({
        message: "Введено некорректное значение",
        color: "negative",
      });
    }
  }
};

const startStage = async (item) => {
  try {
    await axios.post(`item_stages/${item.id}/start`);
    item.status = "work";
  } catch (e) {
    //
  }
};

onMounted(loadItems);
watch(() => props.id, loadItems);
</script>
