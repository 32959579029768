<template>
  <div v-if="fv" class="no-wrap flex items-center q-gutter-x-xs text-caption">
    <span
      class="ellipsis"
      v-if="field.type != 'multiselect' && field.type != 'boolean'"
    >
      <span v-if="fv.type == 'number'">
        {{ numberFormat(fv[fv.type]) }}
      </span>
      <span v-else>{{ fv[fv.type] }}</span>
    </span>
    <span v-else-if="field.type == 'boolean' && fv.json.value == 1">
      {{ field.name }}
    </span>
  </div>
</template>

<script setup>
import { numberFormat } from "@/formatters";
import { defineProps, toRefs } from "vue";

const props = defineProps({
  field: Object,
  fv: Object,
});

const { field, fv } = toRefs(props);
</script>
